<template>
  <div>
    <div class="d-flex row align-items-center twtable-actions">
      <TWTableActions
        v-if="pathToActions.length > 0  && isShowingActionMenu"
        :selectedLength="selectedLength"
        :pathToActions="pathToActions"
        :isActionShown="isActionShown"
        :isActionDisabled="isActionDisabled"
        :actionButton="actionButton"
        :isShowSelected="isShowSelected"
        @onActionSelected="onActionSelected"
        @onActionSelectedFull="onActionSelectedFull"
      />
      <slot name="tableActionsTail" />
    </div>
    <TWTableContextMenu
      ref="contextMenuRef"
      v-if="viewContextMenu && selectedLength > 0"
      :selectedLength="selectedLength"
      :pathToActions="pathToActions"
      :isActionShown="isActionShown"
      :isActionDisabled="isActionDisabled"
      :contextMenuTop="contextMenuTop"
      :contextMenuLeft="contextMenuLeft"
      :clientY="clientY"
      @onActionSelected="onActionSelected"
      @onActionSelectedFull="onActionSelectedFull"
    />
    <TWGrid
      v-if="(data && data.length > 0) || hasFilters"
      :tableData="data"
      :pathToHeader="pathToHeader"
      @onSortBy="onSortBy"
      v-on="$listeners"
      :rowComponentData="rowComponentData"
      @onRefresh="onRefresh"
      @onRightClick="onRightClick"
      @onTWGridDataFilter="onDataFilter"
      :showFilters="showFilters"
      :showGearIcon="showGearIcon"
      :hasFilters="hasFilters"
      :additionalSettings="additionalSettings"
      :scrollSize="scrollSize"
      :isCheckColumnFilterSave="isCheckColumnFilterSave"
      @additionalSettingsCallback="additionalSettingsCallback"
      :canSelectRows = "canSelectRows"
      :totalRowsPositionRelative = "totalRowsPositionRelative"
      :tableFilters="tableFilter"
      :emptyTableMessage="emptyTableMessage"
    />
  </div>
</template>
<script>
import { integer } from 'vuelidate/lib/validators'
import TWGrid from '../shared/TWGrid.vue'
import TWTableActions from '../shared/TWTableActions.vue'
import TWTableContextMenu from '../shared/TWTableContextMenu.vue'
import { defineComponent } from '@vue/composition-api'
export default defineComponent({
  components: {
    TWGrid,
    TWTableActions,
    TWTableContextMenu
  },
  name: 'TWTable',
  data () {
    return {
      viewContextMenu: false,
      contextMenuTop: 0,
      contextMenuLeft: 0,
      clientY: 0
    }
  },
  props: {
    data: {
      type: Array,
      default: function () {
        return []
      }
    },
    isShowingActionMenu: {
      type: Boolean,
      default: function () {
        return true
      }
    },
    pathToActions: {
      type: String,
      default: function () {
        return ''
      }
    },
    pathToHeader: {
      type: String,
      required: true
    },
    isActionShown: {
      type: Function,
      default: function () {
        return true
      }
    },
    isActionDisabled: {
      type: Function,
      default: function () {
        return false
      }
    },
    rowComponentData: {
      type: Object,
      required: true
    },
    showFilters: {
      type: Boolean,
      default: function () {
        return true
      }
    },
    hasFilters: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    showGearIcon: {
      type: Boolean,
      default: false
    },
    additionalSettings: {
      type: Object,
      default: null
    },
    actionButton: {
      type: Object,
      default: null
    },
    scrollSize: {
      type: Number,
      default: 250
    },
    canSelectRows: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    totalRowsPositionRelative: {
      type: Boolean,
      default: false
    },
    isShowSelected: {
      type: Boolean,
      required: false,
      default: false
    },
    tableFilter: {
      type: Object,
      default: function () {
        return {}
      }
    },
    emptyTableMessage: {
      type: String,
      required: false,
      default: ''
    },
    isCheckColumnFilterSave: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    onSortBy ($event) {
      this.$emit('onSortBy', $event)
    },
    onRefresh () {
      this.$emit('onRefresh')
    },
    onActionSelected ($event) {
      this.$emit('onActionSelected', $event)
    },
    onActionSelectedFull ($event) {
      this.$emit('onActionSelectedFull', $event)
    },
    onRightClick (event) {
      if (this.selectedLength > 0) {
        this.viewContextMenu = true
        this.contextMenuLeft = event.layerX
        this.contextMenuTop = event.layerY
        this.clientY = event.clientY
        event.preventDefault()
      }
    },
    onDataFilter (obj) {
      this.$emit('onDataFilter', obj)
    },
    additionalSettingsCallback (value) {
      this.$emit('additionalSettingsCallback', value)
    }
  },
  computed: {
    selectedLength () {
      return this.data.reduce((res, row) => {
        if (row.checked) res++
        return res
      }, 0)
    }
  }
})
</script>
<style scoped>
  .twtable-actions {
    margin-left: 0px;
  }
</style>
