const fuelCards = {
  title: 'tarjetas de combustible',
  noMsg: 'Sin datos de la tarjeta de combustible',
  cardStatus: 'Estado',
  deleteWarningMsg: 'Desasignar tarjeta antes de eliminar',
  deleteConfirmMsg: '¿Está seguro de que desea eliminar la tarjeta de combustible?',
  confirmTitle: 'Eliminar tarjeta de combustible',
  actionHeader: {
    0: {
      title: 'Importación de tarjetas de combustible',
      icon: 'fas fa-fas fa-file-export'
    }
  },
  fuelCardsFilter: {
    0: 'Todas',
    1: 'Activa',
    2: 'Inactiva'
  },

  fuelCardsFormModal: {
    editDlg: {
      title: {
        0: 'Crear tarjetas de combustible',
        1: 'Editar tarjetas de combustible',
        2: 'Eliminar tarjetas de combustible'
      },
      labels: {
        0: 'Número de tarjeta',
        1: 'Número de tarjeta 2',
        2: 'Vehículo/Activos #',
        3: 'VIN',
        4: 'Empleados',
        5: 'Artículo'
      }
    },
    taggedForm: {
      0: 'Información de Registros',
      1: 'creado',
      2: 'Por',
      3: 'modificado'
    }
  },
  itemDetail: {
    subtitle: 'Tarjetas de combustible Añadir artículos',
    addItem: {
      0: {
        title: 'añadir artículo & Atrás',
        icon: 'fas fa-undo'
      }
    },
    errorMessage: 'Por favor seleccione un artículo.'
  },
  ExpandedTableHeader: {
    desktop: [
      {
        label: '',
        key: 'itemType',
        type: 'InKit'
      },
      {
        label: '',
        key: 'hasBleTag',
        type: 'BleTag'
      },
      {
        label: 'Categoría',
        key: 'category',
        sortable: true,
        type: String
      },
      {
        label: 'Número',
        key: 'itemNumber',
        sortable: true,
        type: Number
      },
      {
        label: 'código de barras',
        key: 'barcode',
        sortable: true,
        type: String
      },
      {
        label: 'Número de serie',
        key: 'serialNumber',
        sortable: true,
        type: Number
      },
      {
        label: 'Equipo',
        key: 'kitNumber',
        sortable: true,
        type: Number
      },
      {
        label: 'T',
        key: 'itemType',
        sortable: true,
        type: String
      },
      {
        label: 'Descripción',
        key: 'description',
        sortable: true,
        isMaterial: true,
        type: String
      },
      {
        label: 'Fabricante',
        key: 'manufacturer',
        sortable: true,
        type: String
      },
      {
        label: 'Modelo',
        key: 'model',
        sortable: true,
        isMaterial: true,
        type: String
      },
      {
        label: 'Qty',
        key: 'quantity',
        sortable: true,
        isMaterial: true,
        type: Number
      },
      {
        label: 'Uom',
        key: 'uom',
        sortable: true
      },
      {
        label: 'Estado',
        key: 'itemStatus',
        sortable: true,
        type: String
      },
      {
        label: 'UP',
        key: 'personalIssue',
        sortable: true,
        type: Boolean
      },
      {
        label: 'Asignación',
        key: 'assignedTo',
        sortable: true,
        type: String
      },
      {
        label: 'contenedor',
        key: 'binId',
        sortable: true
      },
      {
        label: 'Personal responsable',
        key: 'responsibleParty',
        sortable: 'true'
      },
      {
        label: 'Periodo',
        key: 'ageDays',
        sortable: true,
        type: 'age',
        sorted: true,
        asc: true
      },
      {
        label: 'Último escaneado',
        key: 'lastScannedOn',
        sortable: true,
        type: Date
      },
      {
        label: 'Última ubicación de escaneo BLE',
        key: 'lastScanLocation',
        type: 'LastScanLocation',
        sortable: true
      },
      {
        label: 'Última área de escaneo BLE',
        key: 'lastScanArea',
        sortable: true
      },
      {
        label: 'último conteo',
        key: 'lastCountDate',
        sortable: true,
        type: Date
      },
      {
        label: 'Tran. No.',
        key: 'lastTransferNumber',
        sortable: true
      },
      {
        label: 'Tran. Fecha',
        key: 'lastTransferDate',
        sortable: true,
        type: Date
      },
      {
        label: 'regresando',
        key: 'returnDate',
        sortable: true,
        type: Date
      },
      {
        label: 'Propietario',
        key: 'owner',
        sortable: true,
        type: String
      },
      {
        label: 'Gerente',
        key: 'manager',
        sortable: true,
        type: String
      },
      {
        label: 'Departamento',
        key: 'department',
        sortable: true,
        type: String
      },
      {
        label: 'Supervisor',
        key: 'supervisor',
        sortable: true,
        type: String
      },
      {
        label: 'Entregado por',
        key: 'lastTransferDeliveredBy',
        sortable: true
      },
      {
        label: 'clase de artículo',
        key: 'className',
        sortable: true,
        type: String
      }
    ],
    mobile: [
      {
        label: '',
        key: 'assetId',
        type: 'check'
      },
      {
        label: '',
        key: 'itemType',
        type: 'InKit'
      },
      {
        label: 'Info',
        data: [
          {
            label: '',
            key: 'hasBleTag',
            type: 'BleTag'
          },
          {
            label: 'Categoría',
            key: 'category',
            sortable: true,
            type: String
          },
          {
            label: 'Número',
            key: 'itemNumber',
            sortable: true,
            type: Number
          },
          {
            label: 'código de barras',
            key: 'barcode',
            sortable: true,
            type: String
          },
          {
            label: 'Número de serie',
            key: 'serialNumber',
            sortable: true,
            type: Number
          },
          {
            label: 'Equipo',
            key: 'kitNumber',
            sortable: true,
            type: Number
          },
          {
            label: 'T',
            key: 'itemType',
            sortable: true,
            type: String
          },
          {
            label: 'Descripción',
            key: 'description',
            sortable: true,
            isMaterial: true,
            type: String
          },
          {
            label: 'Fabricante',
            key: 'manufacturer',
            sortable: true,
            type: String
          },
          {
            label: 'Modelo',
            key: 'model',
            sortable: true,
            isMaterial: true,
            type: String
          },
          {
            label: 'Qty',
            key: 'quantity',
            sortable: true,
            isMaterial: true,
            type: Number
          },
          {
            label: 'Uom',
            key: 'uom',
            sortable: true
          },
          {
            label: 'Estado',
            key: 'itemStatus',
            sortable: true,
            type: String
          },
          {
            label: 'UP',
            key: 'personalIssue',
            sortable: true,
            type: Boolean
          },
          {
            label: 'Asignación',
            key: 'assignedTo',
            sortable: true,
            type: String
          },
          {
            label: 'contenedor',
            key: 'binId',
            sortable: true
          },
          {
            label: 'Personal responsable',
            key: 'responsibleParty',
            sortable: 'true'
          },
          {
            label: 'Periodo',
            key: 'ageDays',
            sortable: true,
            type: 'age',
            sorted: true,
            asc: true
          },
          {
            label: 'Último escaneado',
            key: 'lastScannedOn',
            sortable: true,
            type: Date
          },
          {
            label: 'Última ubicación de escaneo BLE',
            key: 'lastScanLocation',
            type: 'LastScanLocation',
            sortable: true
          },
          {
            label: 'Última área de escaneo BLE',
            key: 'lastScanArea',
            sortable: true
          },
          {
            label: 'último contado',
            key: 'lastCountDate',
            sortable: true,
            type: Date
          },
          {
            label: 'Tran. No.',
            key: 'lastTransferNumber',
            sortable: true
          },
          {
            label: 'Tran. Fecha',
            key: 'lastTransferDate',
            sortable: true,
            type: Date
          },
          {
            label: 'regresado',
            key: 'returnDate',
            sortable: true,
            type: Date
          },
          {
            label: 'Propietario',
            key: 'owner',
            sortable: true,
            type: String
          },
          {
            label: 'Gerente',
            key: 'manager',
            sortable: true,
            type: String
          },
          {
            label: 'Departamento',
            key: 'department',
            sortable: true,
            type: String
          },
          {
            label: 'Supervisor',
            key: 'supervisor',
            sortable: true,
            type: String
          },
          {
            label: 'Entregado por',
            key: 'lastTransferDeliveredBy',
            sortable: true
          },
          {
            label: 'clase de artículo',
            key: 'className',
            sortable: true,
            type: String
          }
        ]
      }
    ]
  },
  ToolCategoryTableHeader: {
    desktop: [
      {
        label: '',
        key: 'itemType',
        type: 'InKit'
      },
      {
        label: 'Categoría',
        key: 'category',
        sortable: true,
        type: String
      },
      {
        label: 'Número',
        key: 'itemNumber',
        sortable: true,
        type: Number
      },
      {
        label: 'código de barras',
        key: 'barcode',
        sortable: true,
        type: String
      },
      {
        label: 'Número de serie',
        key: 'serialNumber',
        sortable: true,
        type: Number
      },
      {
        label: 'Equipo',
        key: 'kitNumber',
        sortable: true,
        type: Number
      },
      {
        label: 'T',
        key: 'itemType',
        sortable: true,
        type: String
      },
      {
        label: 'Descripción',
        key: 'description',
        sortable: true,
        isMaterial: true,
        type: String
      },
      {
        label: 'Fabricante',
        key: 'manufacturer',
        sortable: true,
        type: String
      },
      {
        label: 'Modelo',
        key: 'model',
        sortable: true,
        isMaterial: true,
        type: String
      },
      {
        label: 'Cant.',
        key: 'quantity',
        sortable: true,
        isMaterial: true,
        type: Number
      },
      {
        label: 'Estado',
        key: 'itemStatus',
        sortable: true,
        type: String
      },
      {
        label: 'UP',
        key: 'personalIssue',
        sortable: true,
        type: Boolean
      },
      {
        label: 'Asignación',
        key: 'assignedTo',
        sortable: true,
        type: String
      },
      {
        label: 'Periodo',
        key: 'ageDays',
        sortable: true,
        type: 'age',
        sorted: true,
        asc: true
      },
      {
        label: 'Propietario',
        key: 'owner',
        sortable: true,
        type: String
      },
      {
        label: 'Gerente',
        key: 'manager',
        sortable: true,
        type: String
      },
      {
        label: 'Departamento',
        key: 'department',
        sortable: true,
        type: String
      },
      {
        label: 'Supervisor',
        key: 'supervisor',
        sortable: true,
        type: String
      },
      {
        label: 'clase de artículo',
        key: 'className',
        sortable: true,
        type: String
      }
    ],
    mobile: [
      {
        label: '',
        key: 'itemType',
        type: 'InKit'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Categoría',
            key: 'category',
            sortable: true,
            type: String
          },
          {
            label: 'Número',
            key: 'itemNumber',
            sortable: true,
            type: Number
          },
          {
            label: 'código de barras',
            key: 'barcode',
            sortable: true,
            type: String
          },
          {
            label: 'Número de serie',
            key: 'serialNumber',
            sortable: true,
            type: Number
          },
          {
            label: 'Equipo',
            key: 'kitNumber',
            sortable: true,
            type: Number
          },
          {
            label: 'T',
            key: 'itemType',
            sortable: true,
            type: String
          },
          {
            label: 'Descripción',
            key: 'description',
            sortable: true,
            isMaterial: true,
            type: String
          },
          {
            label: 'Fabricante',
            key: 'manufacturer',
            sortable: true,
            type: String
          },
          {
            label: 'Modelo',
            key: 'model',
            sortable: true,
            isMaterial: true,
            type: String
          },
          {
            label: 'Qty',
            key: 'quantity',
            sortable: true,
            isMaterial: true,
            type: Number
          },
          {
            label: 'Estado',
            key: 'itemStatus',
            sortable: true,
            type: String
          },
          {
            label: 'UP',
            key: 'personalIssue',
            sortable: true,
            type: Boolean
          },
          {
            label: 'Asignación',
            key: 'assignedTo',
            sortable: true,
            type: String
          },
          {
            label: 'Periodo',
            key: 'ageDays',
            sortable: true,
            type: 'age',
            sorted: true,
            asc: true
          },
          {
            label: 'Propietario',
            key: 'owner',
            sortable: true,
            type: String
          },
          {
            label: 'Gerente',
            key: 'manager',
            sortable: true,
            type: String
          },
          {
            label: 'Departamento',
            key: 'department',
            sortable: true,
            type: String
          },
          {
            label: 'Supervisor',
            key: 'supervisor',
            sortable: true,
            type: String
          },
          {
            label: 'clase de artículo',
            key: 'className',
            sortable: true,
            type: String
          }
        ]
      }
    ]
  },
  materialCategoryTableHeader: {
    desktop: [
      {
        label: 'Categoría',
        key: 'category',
        sortable: true,
        type: String
      },
      {
        label: 'Número',
        key: 'itemNumber',
        sortable: true,
        type: String
      },
      {
        label: 'Descripción',
        key: 'description',
        sortable: true,
        type: String
      },
      {
        label: 'Modelo',
        key: 'model',
        sortable: true,
        type: String
      },
      {
        label: 'Cant.',
        key: 'quantity',
        sortable: true,
        type: Number
      },
      {
        label: 'Uom',
        key: 'uom',
        sortable: true,
        type: String
      },
      {
        label: 'ubicación de abastecimineto ',
        key: 'assignedTo',
        sortable: true,
        type: String
      },
      {
        label: 'contenedor',
        key: 'binId',
        sortable: true,
        type: String
      },
      {
        label: 'código de barras',
        key: 'barcode',
        sortable: true,
        type: String
      }
    ],
    mobile: [
      {
        label: 'Info',
        data: [
          {
            label: 'Categoría',
            key: 'category',
            sortable: true,
            type: String
          },
          {
            label: 'Número',
            key: 'itemNumber',
            sortable: true,
            type: String
          },
          {
            label: 'Descripción',
            key: 'description',
            sortable: true,
            type: String
          },
          {
            label: 'Modelo',
            key: 'model',
            sortable: true,
            type: String
          },
          {
            label: 'Cant.',
            key: 'quantity',
            sortable: true,
            type: Number
          },
          {
            label: 'Uom',
            key: 'uom',
            sortable: true,
            type: String
          },
          {
            label: 'ubicación de abastecimineto',
            key: 'assignedTo',
            sortable: true,
            type: String
          },
          {
            label: 'contenedor',
            key: 'binId',
            sortable: true,
            type: String
          },
          {
            label: 'código de barras',
            key: 'barcode',
            sortable: true,
            type: String
          }
        ]
      }
    ]
  },
  fuelCardListHeader: {
    desktop: [
      {
        label: 'Número de tarjeta',
        key: 'cardNumber',
        sortable: true
      },
      {
        label: 'Asignación',
        key: 'assignment',
        sortable: true
      },
      {
        label: 'Estado',
        key: 'status',
        sortable: true
      },
      {
        label: 'Creado en',
        key: 'formattedCreatedOn',
        sortable: true
      },
      {
        label: '',
        key: 'action',
        isEditable: true
      }
    ],
    mobile: [
      {
        label: 'Número de tarjeta',
        key: 'cardNumber',
        sortable: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Asignación',
            key: 'Assignment',
            sortable: true
          },
          {
            label: 'Estado',
            key: 'status',
            sortable: true
          },
          {
            label: 'Creado en',
            key: 'formattedCreatedOn',
            sortable: true
          }
        ]
      }
    ]
  }
}
export default fuelCards
