<template>
  <div class="row">
    <div class="col-lg-12 d-flex flex-wrap">
      <div class="tw-search-holder autocomplete">
        <div class="form-field" :class="isDetail ? 'catalog' : ''">
          <input
            type="text"
            name="q"
            :placeholder="$t('catalog.placeholder')"
            v-model="searchText"
            v-debounce:700ms="onChange"
            @keydown.down="onArrowDown"
            @keydown.up="onArrowUp"
            autocomplete="off"
            @keyup.enter="doSearch()">
        </div>
        <ul
          id="autocomplete-results"
          v-show="isOpen"
          class="autocomplete-results"
        >
          <template v-if="isLoading">
            <li
              class="searchLoading"
            >
              <div class="d-flex justify-content-center">
                <div class="spinner-border text-warning" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </li>
          </template>
          <template v-else-if="results.length">
            <li
              v-for="(result, i) in results"
              :key="i"
              @click="setResult(result)"
              class="autocomplete-result"
              :class="{ 'is-active': i === arrowCounter }"
            >
              <span v-html="boldSuggestion(result)"></span>
            </li>
          </template>
          <template v-else>
            <li class="text-center">
              <span>
                {{$t('catalog.suggestionMsg')}}
              </span>
            </li>
          </template>
        </ul>
        <template v-if="totalEntries > 0 && !isDetail && searchHappened">
          <span class="result-holder">
            {{$tc('message.results', totalEntries, {count: totalEntries})}}
          </span>
        </template>
        <button type="submit" @click="cancelSearch()" v-if="searchText"><i class="fas fa-times"></i></button>
        <button type="submit" @click="doSearch()" v-if="!searchText"><i class="fas fa-search"></i></button>
      </div>
      <ViewMode v-if="totalEntries > 0 && !isDetail && searchHappened" @resetSelect="resetSelect"
        :isCatalogPage="isCatalogPage"
        ></ViewMode>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import ViewMode from '../components/shared/ViewMode.vue'
import { assetKindType } from '../helpers/utils.js'
export default {
  name: 'CatalogNavbar',
  components: {
    ViewMode
  },
  props: {
    SearchText: {
      type: String,
      required: false,
      default: ''
    },
    QueryText: {
      type: String,
      required: false,
      default: ''
    },
    totalEntries: {
      type: Number,
      required: false,
      default: 0
    },
    isDetail: {
      type: Boolean,
      required: false,
      default: false
    },
    searchHappened: {
      type: Boolean,
      required: false
    },
    isShowOnlyTracked: {
      type: Boolean,
      default: true
    },
    isCatalogPage: {
      type: Boolean,
      default: function () {
        return false
      }
    }
  },
  data () {
    return {
      searchText: '',
      oldSearchKey: '',
      isOpen: false,
      arrowCounter: -1,
      results: [],
      isLoading: false,
      typedKey: ''
    }
  },
  created () {
    this.searchText = this.SearchText ? this.SearchText : this.QueryText
    this.oldSearchKey = this.searchText
  },
  mounted () {
    document.addEventListener('click', this.handleClickOutside)
    document.onkeydown = (evt) => {
      evt = evt || window.event
      if (evt.keyCode == 27) {
        this.isOpen = false
        this.arrowCounter = -1
      }
    }
  },
  destroyed () {
    document.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    async doSearch () {
      if (this.isOpen && this.arrowCounter !== -1) {
        this.searchText = this.results[this.arrowCounter].toLowerCase()
        this.isOpen = false
        this.arrowCounter = -1
      }
      this.isOpen = false
      localStorage.setItem('catalog-searchText', this.searchText)
      this.$store.commit('setSearchCriteria', {
        q: this.searchText
      })
      await this.updateParameters()
    },
    async cancelSearch () {
      this.searchText = ''
      localStorage.setItem('catalog-searchText', '')
      this.isOpen = false
      this.$store.commit('setSearchCriteria', {
        q: this.searchText
      })
      await this.updateParameters()
    },
    resetSelect () {
      localStorage.removeItem('catalog-tableFilters')
      this.$emit('getStartingManufacturersAndCategories')
    },
    async updateParameters () {
      const assetKind = this.$route.query.assetKind
        ? this.$route.query.assetKind
        : 'A'
      delete this.$route.query.masterId
      delete this.$route.query.catalogModelId
      try {
        const obj = {
          path: '/catalog/search/',
          query: {
            ...this.$route.query,
            assetKind: assetKind,
            q: this.searchText,
            pageNumber: 1
          }
        }
        localStorage.setItem('catalog-reset-url', false)
        this.$emit('setAssetKindUrl', {
          AssetKind: assetKind,
          Route: obj
        })
        await this.$router.push(obj)
      } catch (error) {
      }
    },
    async onChange (key, event) {
      if ((event.keyCode < 41 && event.keyCode > 36) ||
      this.searchText === this.oldSearchKey) return
      if (this.searchText === '') {
        this.isOpen = false
        this.oldSearchKey = ''
        this.results = []
        return
      }
      this.typedKey = key
      this.oldSearchKey = this.searchText
      this.isLoading = true
      this.isOpen = true
      if (this.catalogType === 2) {
        const searchParams = JSON.stringify({ text: '\'' + this.searchText + '\'', max: 10 })
        const { data } = await axios.post('/api/core/CatalogSearch/Suggest', searchParams)
        this.isLoading = false
        this.results = data.Body
        this.arrowCounter = -1
      } else {
        const assetKind = this.$route.query.assetKind
        const params = JSON.stringify({
          searchText: this.searchText,
          assetKind: assetKindType(assetKind),
          limit: 15,
          isModelIdSearch: true
        })
        const searchParams = { searchParams: params }
        const { data } = await axios.post('/api/core/ModelSearchService/GetSearchSuggestions', searchParams)
        this.isLoading = false
        this.results = data.Body
        this.arrowCounter = -1
      }
    },
    setResult (result) {
      this.searchText = result
      this.isOpen = false
      this.arrowCounter = -1
      this.doSearch()
    },
    onArrowDown () {
      if (this.arrowCounter < this.results.length - 1) {
        this.arrowCounter = this.arrowCounter + 1
        this.searchText = this.results[this.arrowCounter].toLowerCase()
        this.oldSearchKey = this.results[this.arrowCounter].toLowerCase()
      }
    },
    onArrowUp () {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1
        this.searchText = this.results[this.arrowCounter].toLowerCase()
        this.oldSearchKey = this.results[this.arrowCounter].toLowerCase()
      }
    },
    handleClickOutside (event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false
        this.arrowCounter = -1
      }
    },
    boldSuggestion (result) {
      if (!this.typedKey) {
        return result
      }
      return result && result.toLowerCase().replace(
        this.typedKey.toLowerCase(),
        '<span style="font-weight: bold">' +
        this.typedKey.toLowerCase() + '</span>'
      )
    }
  },
  computed: {
    catalogType () {
      return this.isShowOnlyTracked ? 1 : 2
    }
  },
  watch: {
    SearchText: function (newval) {
      this.searchText = newval
    }
  }
}
</script>
<style>
  :root {
    --secondary-color: #D09433;
  }
  .autocomplete {
    position: relative;
  }
  .searchLoading {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 100;
    background-color: transparent;
    /* background: rgba(0,0,0, 0.25); */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .autocomplete-results {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    margin: 0;
    border: 1px solid #eeeeee;
    min-height: 60px;
    /* max-height: 200px; */
    overflow: auto;
    position: absolute;
    z-index: 120;
    background: #FAFAF9;
    top: 40px;
    max-width: 100%;
    width: 530px;
    border-radius: 5px;
    box-shadow: 0px 0px 7px 1px rgb(0 0 0 / 6%);
  }

  .autocomplete-result {
    list-style: none;
    text-align: left;
    padding: 4px 2px;
    cursor: pointer;
  }

  .autocomplete-result.is-active,
  .autocomplete-result:hover {
    background-color: var(--secondary-color);
    color: white;
  }
</style>
