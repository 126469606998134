const itemBrowser = {
  tool: {
    title: 'Explorador de artículos',
    equipmentTitle: 'Navegador de equipos',
    modelBrowser: 'Navegador de modelos de herramientas',
    actionHeader: {
      0: {
        title: 'Añadir artículo',
        icon: 'fas fa-plus'
      },
      3: {
        title: 'Impresión',
        icon: 'fas fa-print'
      },
      4: {
        title: 'Exportar',
        icon: 'fas fa-file-export'
      }
    },
    csvHeader: [
      {
        label: 'categoría'
      },
      {
        label: 'modelo'
      },
      {
        label: 'tipo de artículo'
      },
      {
        label: 'código de barras'
      },
      {
        label: 'número de articulo'
      },
      {
        label: 'asignado a'
      },
      {
        label: 'dueño'
      },
      {
        label: 'equipo no.'
      },
      {
        label: 'descripción'
      },
      {
        label: 'fabricante'
      },
      {
        label: 'UP'
      },
      {
        label: 'estado del articulo'
      },
      {
        label: 'gerente'
      },
      {
        label: 'cantidad'
      },
      {
        label: 'supervisor'
      },
      {
        label: 'departamento'
      },
      {
        label: 'uom'
      },
      {
        label: 'número de serie'
      },
      {
        label: 'nombre de la clase'
      },
      {
        label: 'edad en dias'
      },
      {
        label: 'icono'
      },
      {
        label: 'costo de remplazo'
      },
      {
        label: 'notas'
      }
    ],
    csvExpandedHeader: [
      {
        label: 'categoría'
      },
      {
        label: 'modelo'
      },
      {
        label: 'tipo de artículo'
      },
      {
        label: 'código de barras'
      },
      {
        label: 'número de articulo'
      },
      {
        label: 'fecha de retorno'
      },
      {
        label: 'asignado a'
      },
      {
        label: 'dueño'
      },
      {
        label: 'equipo'
      },
      {
        label: 'descripción'
      },
      {
        label: 'fabricante'
      },
      {
        label: 'UP'
      },
      {
        label: 'estado'
      },
      {
        label: 'gerente'
      },
      {
        label: 'cantidad'
      },
      {
        label: 'supervisor'
      },
      {
        label: 'última transferencia entregada por'
      },
      {
        label: 'última fecha de transferencia'
      },
      {
        label: 'último número de transferencia'
      },
      {
        label: 'departamento'
      },
      {
        label: 'uom'
      },
      {
        label: 'número de serie'
      },
      {
        label: 'ID contenedor'
      },
      {
        label: 'última fecha de conteo'
      },
      {
        label: 'nombre de la clase'
      },
      {
        label: 'persona responsable'
      },
      {
        label: 'última fecha de escaneado'
      },
      {
        label: 'edad en dias'
      },
      {
        label: 'última área escaneada'
      },
      {
        label: 'ubicación del último escaneo'
      },
      {
        label: 'icono'
      },
      {
        label: 'ble'
      },
      {
        label: 'costo de remplazo'
      },
      {
        label: 'notas'
      },
      {
        label: 'kit'
      },
      {
        label: 'vin'
      },
      {
        label: 'estadoDeLaPlaca'
      },
      {
        label: 'numerodeplaca'
      },
      {
        label: 'necesitaverificar'
      },
      {
        label: 'metro'
      },
      {
        label: 'añodelmodelo'
      },
      {
        label: 'inalámbrico'
      },
      {
        label: 'disponible'
      },
      {
        label: 'asignado'
      },
      {
        label: 'creadoen'
      },
      {
        label: 'creadopor'
      }
    ],
    type: [
      {
        key: '\xa0',
        value: 0
      },
      {
        key: 'Herramienta Única',
        value: 1
      },
      {
        key: 'Herramienta Cantidad',
        value: 2
      },
      {
        key: 'Equipo',
        value: 1
      }
    ],
    lineActions: [
      {
        title: 'Añadir artículo'
      },
      {
        title: 'Impresión'
      },
      {
        title: 'Exportar'
      },
      {
        title: '------------------------'
      },
      {
        title: 'Añadir un adjunto'
      },
      {
        title: 'Agregar para transferir'
      },
      {
        title: 'Ver Detalle & Editar elemento'
      },
      {
        title: 'Ver historial de medidores'
      },
      {
        title: 'Ver historial de horas'
      },
      {
        title: 'Ver historial de transacciones'
      },
      {
        title: 'Ver historial de envejecimiento'
      },
      {
        title: 'Ver escaneos'
      },
      {
        title: 'Herramienta de retiro'
      },
      {
        title: 'Eliminar equipo'
      },
      {
        title: 'Establecer personal responsable'
      },
      {
        title: 'Cambiar dueño'
      },
      {
        title: 'Cambiar Estado'
      },
      {
        title: 'Cambiar Departamento'
      },
      {
        title: 'Cambiar clase de artículo'
      },
      {
        title: 'Cambiar fecha de devolución'
      },
      {
        title: 'Ajustar inventario'
      },
      {
        title: 'Ver historial de ajustes'
      },
      {
        title: 'Eliminar registro de inventario'
      },
      {
        title: 'Agregar al equipo'
      },
      {
        title: 'Cambiar modelo'
      },
      {
        title: 'Editar equipo'
      },
      {
        title: 'Agregar a etiqueta de selección'
      },
      {
        title: 'Agregar a la orden de compra'
      },
      {
        title: 'Crear solicitud de servicio'
      },
      {
        title: 'Crear orden de trabajo'
      },
      {
        title: 'Agregar al boleto de conteo'
      }
    ],
    viewMeterHistory: {
      title: 'Historial del medidor',
      tableHeader: {
        desktop: [
          {
            label: 'Nivel del medidor',
            key: 'MeterLevel',
            sortable: true
          },
          {
            label: 'Creado en',
            key: 'CreatedOn',
            type: Date,
            sortable: true
          },
          {
            label: 'Creado por',
            key: 'CreatedBy',
            sortable: true
          },
          {
            label: 'Origen',
            key: 'SourceId',
            type: 'source',
            sortable: true
          }
        ],
        mobile: [
          {
            label: 'Nivel del medidor',
            key: 'MeterLevel',
            sortable: true
          },
          {
            label: 'Info',
            data: [
              {
                label: 'Creado en',
                key: 'CreatedOn',
                type: Date,
                sortable: true
              },
              {
                label: 'Creado por',
                key: 'CreatedBy',
                sortable: true
              },
              {
                label: 'Origen',
                key: 'SourceId',
                type: 'source',
                sortable: true
              }
            ]
          }
        ]
      }
    },
    viewHoursHistory: {
      title: 'Historial de horas',
      tableHeader: {
        desktop: [
          {
            label: 'Horas',
            key: 'EquipmentHours',
            sortable: true
          },
          {
            label: 'Creado en',
            key: 'CreatedOn',
            type: Date,
            sortable: true
          },
          {
            label: 'Creado por',
            key: 'CreatedBy',
            sortable: true
          },
          {
            label: 'Origen',
            key: 'SourceId',
            type: 'source',
            sortable: true
          }
        ],
        mobile: [
          {
            label: 'Historial de horas',
            key: 'EquipmentHours',
            sortable: true
          },
          {
            label: 'Info',
            data: [
              {
                label: 'Creado en',
                key: 'CreatedOn',
                type: Date,
                sortable: true
              },
              {
                label: 'Creado por',
                key: 'CreatedBy',
                sortable: true
              },
              {
                label: 'Origen',
                key: 'SourceId',
                type: 'source',
                sortable: true
              }
            ]
          }
        ]
      }
    },
    transferHistory: {
      title: 'Historial de transferencias',
      tableHeader: {
        desktop: [
          {
            label: 'Tran. Fecha',
            type: 'DateAndTime',
            key: 'TransactionDate',
            sortable: true
          },
          {
            label: 'Tran. No.',
            key: 'TransferNumber',
            sortable: true
          },
          {
            label: 'Tipo',
            key: 'TransactionType',
            sortable: true
          },
          {
            label: 'Descripción',
            key: 'MasterDescription',
            sortable: true
          },
          {
            label: 'Cantidad',
            key: 'Quantity',
            sortable: true
          },
          {
            label: 'Asignado a',
            key: 'AssignedTo',
            sortable: true
          },
          {
            label: 'Fecha asignada',
            type: 'DateAndTime',
            key: 'AssignmentDate',
            sortable: true
          },
          {
            label: 'Estado',
            key: 'Status',
            sortable: true
          },
          {
            label: 'UP',
            type: Boolean,
            key: 'PersonalIssue',
            sortable: true
          },
          {
            label: 'Dueño',
            key: 'Owner',
            sortable: true
          },
          {
            label: 'Responsible Party',
            key: 'ResponsibleParty',
            sortable: true
          },
          {
            label: 'Equipo',
            key: 'KitNumber',
            sortable: true
          }
        ],
        mobile: [
          {
            label: 'Tran. Fecha',
            type: Date,
            key: 'TransactionDate',
            sortable: true
          },
          {
            label: 'Info',
            data: [
              {
                label: 'Tran. No.',
                key: 'TransferNumber',
                sortable: true
              },
              {
                label: 'Tipo',
                key: 'TransactionType',
                sortable: true
              },
              {
                label: 'Descripción',
                key: 'MasterDescription',
                sortable: true
              },
              {
                label: 'Cantidad',
                key: 'Quantity',
                sortable: true
              },
              {
                label: 'Asignado a',
                key: 'AssignedTo',
                sortable: true
              },
              {
                label: 'Fecha asignada',
                type: Date,
                key: 'AssignmentDate',
                sortable: true
              },
              {
                label: 'Estado',
                key: 'Status',
                sortable: true
              },
              {
                label: 'UP',
                type: Boolean,
                key: 'PersonalIssue',
                sortable: true
              },
              {
                label: 'Dueño',
                key: 'Owner',
                sortable: true
              },
              {
                label: 'Responsible Party',
                key: 'ResponsibleParty',
                sortable: true
              },
              {
                label: 'Equipo',
                key: 'KitNumber',
                sortable: true
              }
            ]
          }
        ]
      }
    },
    agingHistory: {
      title: 'Historial de envejecimiento',
      scanTitle: 'Escaneos',
      tableHeader: {
        desktop: [
          {
            label: '',
            key: 'ConsecutiveScans',
            type: 'ScanCount',
            sortable: true
          },
          {
            label: 'Tiempo escaneado',
            type: 'DateAndTime',
            sortable: true,
            key: 'ScannedOn'
          },
          {
            label: 'Tipo de scaneado',
            sortable: true,
            type: 'trackingSourceType',
            key: 'SourceType'
          },
          {
            label: 'Escaneado por',
            sortable: true,
            key: 'ScannedByDescription'
          },
          {
            label: 'Ubicación de escaneo',
            sortable: true,
            key: 'ScannedAtDescription'
          },
          {
            label: 'Área de escaneo',
            sortable: true,
            key: 'ScannedAtArea'
          },
          {
            label: 'Latitud',
            sortable: true,
            key: 'ScannedAtLatitude'
          },
          {
            label: 'Longitud',
            sortable: true,
            key: 'ScannedAtLongitude'
          }
        ],
        mobile: [
          {
            label: '',
            key: 'ConsecutiveScans',
            type: 'ScanCount',
            sortable: true
          },
          {
            label: 'Tiempo escaneado',
            type: 'DateAndTime',
            sortable: true,
            key: 'ScannedOn'
          },
          {
            label: 'Info',
            data: [
              {
                label: 'Tipo de scaneado',
                sortable: true,
                type: 'trackingSourceType',
                key: 'SourceType'
              },
              {
                label: 'Escaneado por',
                sortable: true,
                key: 'ScannedByDescription'
              },
              {
                label: 'Ubicación de escaneo',
                sortable: true,
                key: 'ScannedAtDescription'
              },
              {
                label: 'Área de escaneo',
                sortable: true,
                key: 'ScannedAtArea'
              },
              {
                label: 'Latitud',
                sortable: true,
                key: 'ScannedAtLatitude'
              },
              {
                label: 'Longitud',
                sortable: true,
                key: 'ScannedAtLongitude'
              }
            ]
          }
        ]
      },
      toolTipInfo: 'Este elemento se escaneó {count} veces en esta ubicación entre {firstDate} y {lastDate}'
    },
    adjustmentHistory: {
      title: 'Historial de ajustes',
      tableHeader: {
        desktop: [
          {
            label: 'Fecha de ajuste',
            type: 'DateAndTime',
            key: 'AdjustmentDate',
            sortable: true
          },
          {
            label: 'ubicación de Abastecimiento',
            key: 'StockLocation',
            sortable: true
          },
          {
            label: 'Cantidad inicial',
            key: 'CurrentQuantity',
            sortable: true
          },
          {
            label: 'Cantidad ajustada',
            key: 'NewQuantity',
            sortable: true
          },
          {
            label: 'Diferencia',
            key: 'DeltaQuantity',
            sortable: true
          },
          {
            label: 'Ajustado por',
            key: 'AdjustedBy',
            sortable: true
          },
          {
            label: 'Razón',
            key: 'Reason',
            sortable: true
          },
          {
            label: 'Documento núm.:',
            key: 'DocumentNumber',
            sortable: true
          },
          {
            label: 'Conteo completo',
            type: Boolean,
            key: 'FullCount',
            sortable: true
          }
        ],
        mobile: [
          {
            label: 'Fecha de ajuste',
            type: 'DateAndTime',
            key: 'AdjustmentDate',
            sortable: true
          },
          {
            label: 'Info',
            data: [
              {
                label: 'ubicación de existencias',
                key: 'StockLocation',
                sortable: true
              },
              {
                label: 'Cantidad inicial',
                key: 'CurrentQuantity',
                sortable: true
              },
              {
                label: 'Cantidad ajustada',
                key: 'NewQuantity',
                sortable: true
              },
              {
                label: 'Diferencia',
                key: 'DeltaQuantity',
                sortable: true
              },
              {
                label: 'Ajustado por',
                key: 'AdjustedBy',
                sortable: true
              },
              {
                label: 'Razón',
                key: 'Reason',
                sortable: true
              },
              {
                label: 'Documento núm.:',
                key: 'DocumentNumber',
                sortable: true
              },
              {
                label: 'Conteo completo',
                type: Boolean,
                key: 'FullCount',
                sortable: true
              }
            ]
          }
        ]
      }
    },
    setResponsibleParty: {
      title: 'Establecer parte responsable',
      dlgHeaders: {
        0: 'No se puede establecer el personal responsable',
        1: 'Seleccione una parte responsable para un elemento seleccionado | Seleccione una parte responsable de los {count} elementos seleccionados.'
      },
      dlgTexts: {
        0: 'No puede configurar el personal responsable para los artículos asignados a una ubicación de Abastecimiento.',
        1: 'No puede configurar el personal responsable para artículos rastreados por cantidad.',
        2: 'Una selección en blanco eliminará el personal responsable actual.'
      }
    },
    sendToSiteManager: '1 artículo ha sido enviado al administrador del sitio | Se han enviado {count} elementos al administrador del sitio',
    changeOwner: {
      title: 'Cambio de dueño',
      text: 'Está a punto de cambiar el dueño de 1a herramienta. ¿Estas seguro que deseas continuar? | Está a punto de cambiar el dueño de {count} herramientas. ¿Estas seguro que deseas continuar?',
      label: 'Owner'
    },
    changeStatus: {
      title: 'Cambiar Estado',
      text: 'Está a punto de eliminar el estado de 1a herramienta. ¿Estas seguro que deseas continuar? | Está a punto de eliminar el estado de {count} herramientas. ¿Estas seguro que deseas continuar?',
      label: 'Estado'
    },
    changeDepartment: {
      title: 'Cambiar Departamento',
      text: 'Ha seleccionado 1 herramienta única para un cambio de departamento. ¿Desea continuar?| Ha seleccionado {count} herramientas únicas para un cambio de departamento. ¿Desea continuar?',
      label: 'Departamento nuevo'
    },
    changeItemClass: {
      title: 'Cambiar clase de artículo',
      text: 'Ha seleccionado 1 herramienta con seguimiento único para un cambio de clase. ¿Desea continuar? | Ha seleccionado {count} herramientas con seguimiento único para un cambio de clase. ¿Desea continuar?',
      label: 'Nueva clase de artículo'
    },
    cannotChangeItemClass: {
      title: 'Cambiar clase de artículo',
      message: 'Solo las herramientas con seguimiento único pueden tener una clase de artículo asignada aquí. No se pueden cambiar las clases de artículos.'
    },
    changeReturnDate: {
      title: 'Cambiar fecha de devolución',
      text: 'Estás a punto de cambiar la fecha de devolución de 1 transferencia. Todas las herramientas que seleccionó que no estaban asignadas se han ignorado. ¿Estas seguro que deseas continuar? | Está a punto de cambiar la fecha de devolución de {count} transferencias. Todas las herramientas que seleccionó que no estaban asignadas se han ignorado. ¿Estas seguro que deseas continuar?',
      label: 'Fecha de regreso',
      noTransferWarning: 'Sin transferencias seleccionadas'
    },
    changeModel: {
      differntTrackingTypes: 'Estos elementos son de diferentes tipos de seguimiento y no se pueden configurar para el mismo modelo',
      differentModelAssignment: 'Los elementos no se pueden reasignar al modelo mediante otros métodos de seguimiento.',
      changeKitsModel: 'Los kits no se pueden reasignar a otros modelos.'
    },
    removeInventoryRecord: {
      title: 'No se puede borrar',
      message: 'Solo puede eliminar registros de inventario cuando su cantidad disponible es cero.'
    },
    kit: {
      delete: {
        warningTitle: 'Eliminar eqipo',
        warningMsg: 'Este proceso eliminará el kit y devolverá las herramientas del kit al inventario. ¿Confirmar la eliminación de {description}?'
      },
      kitWarning: 'El artículo está actualmente asociado con otro equipo. ¿Le gustaría quitar el artículo de ese equipo y agregarlo a este?',
      kitWarningItemToTransfer: 'Este artículo está en un kit. ¿Le gustaría eliminar el artículo del kit o continuar con la transferencia?',
      labels: {
        0: 'Crear nueva asociación de equipo',
        1: 'Agregar elementos'
      }
    }
  },
  material: {
    title: 'Navegador de materiales',
    modelBrowser: 'Navegador de modelos de materiales',
    actionHeader: {
      0: {
        title: 'Explorador de artículos',
        icon: 'fas fa-plus'
      },
      1: {
        title: 'Editar elemento',
        icon: 'fas fa-edit'
      },
      2: {
        title: 'Impresión',
        icon: 'fas fa-print'
      },
      3: {
        title: 'Exportar datos',
        icon: 'fas fa-file-export'
      }
    },
    csvHeader: [
      {
        label: 'categoría'
      },
      {
        label: 'modelo'
      },
      {
        label: 'código de barras'
      },
      {
        label: 'número'
      },
      {
        label: 'asignación'
      },
      {
        label: 'descripción'
      },
      {
        label: 'cantidad'
      },
      {
        label: 'uom'
      },
      {
        label: 'costo de remplazo'
      },
      {
        label: 'Id de contenedor'
      },
      {
        label: 'notas'
      },
      {
        label: 'disponible',
        flag: 'isShowPickedItemState'
      },
      {
        label: 'asignado',
        flag: 'isShowPickedItemState'
      }
    ]
  },
  searchForm: {
    labels: {
      0: 'Búsqueda Avanzada',
      1: 'Artículo No.',
      2: 'Tipo',
      3: 'Número de serie',
      4: 'Artículos etiquetados inalámbricos',
      5: 'Estado',
      6: 'Clase',
      7: 'Certificación',
      8: 'Asignación',
      9: 'Departamento',
      10: 'Supervisor',
      11: 'Equipo no.',
      12: 'Personal responsable',
      13: 'Número',
      14: 'Ubicación de Abastecimiento',
      15: 'Artículo No/código de barras',
      16: 'Vin',
      17: 'Número de placa',
      18: 'Estado de la placa'
    }
  },
  templateBrowser: 'Navegador de Plantillas',
  showFullyAllocatedItems: 'Mostrar elementos completamente asignados',
  print: {
    title: 'Opciones de informe',
    reportLayout: 'Diseño de informe',
    availableReportOptions: 'Opciones de informe disponibles...',
    groupBy: 'Agrupar por',
    sortBy: 'Ordenar por',
    printOptions: 'Opciones de impresión',
    exportOptions: 'Opciones de exportación',
    all: 'Todas',
    selected: 'Seleccionado',
    noItemsSelected: 'No hay artículos seleccionados',
    counted: 'Contado',
    uncounted: 'No-contados',
    printBarCodes: 'Imprimir códigos de barras',
    printPhotos: 'Imprimir fotos',
    UserDefined: 'Definida por el usuario',
    allFields: 'Todos los campos',
    printWithComments: 'Imprimir con comentarios de transferencia'
  }
}

export default itemBrowser
