<template>
  <div
    aria-hidden="true"
    aria-labelledby="catalogImportModel"
    class="modal fade tw-shipping-modal"
    data-backdrop="static"
    id="catalogImportModal"
    role="dialog"
    tabindex="-1"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            v-if="newItemFromScratch"
            class="modal-title"
            id="categoriesLabel"
          >
            {{ $t("catalog.createModal.title") }}
          </h5>
          <h5 v-else class="modal-title">
            {{
              masterId !== null && itemType !== "T"
                ? inventoryStocked
                  ? "Stop Stocking"
                  : "Start Stocking"
                : "Create"
            }}
            {{ [manufacturer, model].filter((x) => x).join(" ") }}
          </h5>
          <button aria-label="Close" class="close" type="button" @click="close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form ref="importModelForm" id="importModelForm" v-on:submit.prevent>
            <div
              v-if="duplicateModel"
              class="alert alert-danger mx-4 my-2"
              role="alert"
            >
              {{
                $tc(
                  "catalog.createModal.duplicateMsg",
                  duplicateModel.Model
                )
              }}
              <a
                href="javascript:void(0);"
                @click="onDuplicateClick()"
                class="mb-2 text-dark font-weight-bold"
                >{{ $t("catalog.viewModel") }}</a
              >
            </div>

            <div
              v-if="dewaltUsn && dewaltUsn.DuplicateSerialExistsInOrg"
              class="alert alert-danger mx-4 my-2"
              role="alert"
            >
              <!-- UNABLE TO ADD ITEM. This item utilizes a DeWALT digital Unique Serial Number that is already in use.
              See {{dewaltUsn.DuplicateItemModelNumber}}, item number {{dewaltUsn.DuplicateItemNumber}}. -->
              {{
                $tc(
                  "catalog.createModal.dewaltMsg",
                  dewaltUsn.DuplicateItemModelNumber,
                  dewaltUsn.DuplicateItemNumber
                )
              }}
            </div>
            <div
              v-else-if="dewaltUsn"
              class="alert alert-warning mx-4 my-2"
              role="alert"
            >
              {{ $t("catalog.createModal.dewaltUSN") }}
            </div>

            <input
              v-if="currentStockpointId"
              type="hidden"
              id="csp"
              name="csp"
              v-model="currentStockpointId"
            />

            <input
              v-if="masterId"
              type="hidden"
              id="masterId"
              name="masterId"
              v-model="masterId"
            />

            <input
              v-if="catalogModelId"
              type="hidden"
              id="catalogModelId"
              name="catalogModelId"
              v-model="catalogModelId"
            />
            <CatalogFormInputLine
              :class="{ 'pt-2': !duplicateModel }"
              ref="itemTypeInputLine"
              :formId="'itemType'"
              :displayLabel="$t('catalog.createModal.itemType.label')"
              :initialValue="
                newItemFromScratch || masterId === null
                  ? ''
                  : itemSubTypeCheck
              "
              :selectOptions="[
                $t('catalog.createModal.itemType.options.0'),
                $t('catalog.createModal.itemType.options.2'),
                $t('catalog.createModal.itemType.options.1'),
              ]"
              :isEditable="newItemFromScratch || masterId === null"
              :isRequired="newItemFromScratch || masterId === null"
              :isDisabled="attemptingAddItem || attemptingAddItemAndNext"
              :isComboSelector="true"
              @selectionChange="onItemTypeSet"
            >
            </CatalogFormInputLine>
            <CatalogFormInputLine
              :formId="'trackingMethod'"
              ref="trackingMethodInputLine"
              :displayLabel="$t('catalog.createModal.trackingMethod.label')"
              :initialValue="
                newItemFromScratch || masterId === null
                  ? ''
                  : itemType === 'T'
                  ? 'Unique ID'
                  : 'Quantity'
              "
              :selectOptions="[
                $t('catalog.createModal.trackingMethod.options.0'),
                $t('catalog.createModal.trackingMethod.options.1'),
              ]"
              :isEditable="newItemFromScratch || masterId === null"
              :isRequired="newItemFromScratch || masterId === null"
              :isDisabled="attemptingAddItem || attemptingAddItemAndNext"
              :isComboSelector="true"
              @selectionChange="onTrackingMethodSet"
            >
            </CatalogFormInputLine>
            <CatalogFormInputLine
              v-if="!newItemFromScratch"
              :formId="'manufacturer'"
              :displayLabel="$t('catalog.createModal.modalLabels.0')"
              :initialValue="manufacturer"
              :isEditable="false"
            >
            </CatalogFormInputLine>
            <CatalogFormInputLine
              v-else
              ref="manufacturerInputLine"
              :formId="'manufacturer'"
              :displayLabel="$t('catalog.createModal.modalLabels.0')"
              :initialValue="manufacturer"
              :selectOptions="manufacturers"
              :isLoading="isLoadingManufacturers"
              :isEditable="true"
              :isRequired="true"
              :manufacturerId="manufacturerId"
              @selectionChange="onManufacturerSelected"
            >
            </CatalogFormInputLine>
            <template>
              <template>
                <div
                  aria-hidden="true"
                  aria-labelledby="manufacturersForm"
                  class="modal fade tw-shipping-modal"
                  id="manufacturersForm"
                  data-backdrop="static"
                  role="dialog"
                  tabindex="-1" v-if="selectedItemForManufacturer">
                  <manufacturers-form
                    ref="manufacturersForm"
                    :key="modalCountManufacturer"
                    :selectedItem="selectedItemForManufacturer"
                    :selectedAction="'create'"
                    @hideModal="closeManufacturerForm"
                    @openConfirmSaveClose="openConfirmSaveClose(false)"
                    @saveFailure="closeManufacturerForm"
                    @saveSuccess="onSaveManufacturerSuccess">
                  </manufacturers-form>
                </div>
              </template>
              <item-attachments ref="itemAttachments"></item-attachments>
            </template>
            <CatalogFormInputLine
              ref="categoryInputLine"
              :formId="'category'"
              :displayLabel="$t('catalog.createModal.modalLabels.2')"
              :initialValue="masterId === null ? '' : category"
              :selectOptions="categories"
              :isLoading="isLoadingCategories"
              :isEditable="newItemFromScratch || masterId === null"
              :isRequired="newItemFromScratch || masterId === null"
              :isDisabled="attemptingAddItem || attemptingAddItemAndNext"
              :categoryId="categoryId"
              @selectionChange="onCategorySelected"
            >
            </CatalogFormInputLine>
            <template>
              <div
                aria-hidden="true"
                aria-labelledby="categoryForm"
                class="modal fade tw-shipping-modal"
                id="categoryForm"
                data-backdrop="static"
                role="dialog"
                tabindex="-1" v-if="selectedItem">
                <category-form
                  :key="modalCount"
                  ref="categoryForm"
                  :category="categoryPopoupTitle"
                  :selectedItem="selectedItem"
                  :numberType="numberType"
                  :selectedAction="'create'"
                  @hideModal="closeCategoryForm"
                  @openConfirmSaveClose="openConfirmSaveClose(true)"
                  @saveFailure="onSaveCategoryFailure"
                  @saveSuccess="onSaveCategorySuccess"
                  @cancel="onCancelAddCategory">
                </category-form>
              </div>
              <div>
                <confirm-dialog ref="confirmSaveClose"
                  class="tw-shipping-modal"
                  :isOverlapping="true"
                  :maxWidth="'500px;'"
                  :title="confirmDlgTitle"
                  :message="message"
                  :okBtnText="$t('buttons.yes')"
                  :cancelBtnText="$t('buttons.no')"
                  @confirmResult="onConfirmSaveClose"
                >
                </confirm-dialog>
              </div>
            </template>

            <CatalogFormInputLine
              :formId="'modelName'"
              :displayLabel="$t('catalog.createModal.modalLabels.3')"
              :initialValue="model"
              :isEditable="newItemFromScratch"
              :isRequired="newItemFromScratch"
            >
            </CatalogFormInputLine>

            <CatalogFormInputLine
              v-if="!newItemFromScratch && catalogNumber"
              :formId="'catalogNumber'"
              :displayLabel="$t('catalog.createModal.modalLabels.4')"
              :initialValue="catalogNumber"
              :isEditable="false"
              :isRequired="false"
            >
            </CatalogFormInputLine>
            <CatalogFormInputLine
              v-if="!newItemFromScratch && upc"
              :formId="'upc'"
              :displayLabel="$t('catalog.createModal.modalLabels.5')"
              :initialValue="upc ? upc : ''"
              :isEditable="false"
              :isRequired="false"
            >
            </CatalogFormInputLine>

            <CatalogFormInputLine
              v-if="!newItemFromScratch && year"
              :formId="'year'"
              :displayLabel="$t('catalog.createModal.modalLabels.6')"
              :initialValue="year ? year : ''"
              :isEditable="false"
              :isRequired="false"
              :maxlength="4"
            >
            </CatalogFormInputLine>

            <CatalogFormInputLine
              :formId="'description'"
              :displayLabel="$t('catalog.createModal.modalLabels.7')"
              :initialValue="masterId === null ? '' : description"
              :suggestedValue="description ? description.substring(0, 99) : ''"
              :suggestedPrompt="description ? 'Use Manufacturer Description' : ''"
              :isEditable="newItemFromScratch || masterId === null"
              :isRequired="newItemFromScratch || masterId === null"
              :maxlength="100"
              :isDisabled="attemptingAddItem || attemptingAddItemAndNext"
            >
            </CatalogFormInputLine>

            <div
              v-if="isExistingItem"
              class="px-4 pt-1"
              style="position: relative"
            >
              <div>
                <div class="form-group row mb-0 pb-2">
                  <label class="col-md-3 col-form-label font-weight-bold">{{
                    $t("catalog.createModal.modalLabels.11")
                  }}</label>
                  <div class="col-md-9">
                    <DatePicker
                      :key="this.catalogRecord.CatalogModelId"
                      :date="purchaseDate"
                      :classic="true"
                      @onDateChanged="purchaseDate = $event"
                    >
                    </DatePicker>
                  </div>
                </div>
              </div>
            </div>

            <CatalogFormInputLine
              v-if="isExistingItem"
              ref="purchaseCost"
              :formId="'purchaseCost'"
              :displayLabel="$t('catalog.createModal.modalLabels.12')"
              :initialValue="this.formatter.format(purchaseCost)"
              :maxlength="10"
              :isDisabled="false"
              :isCurrencyInput="true"
            >
            </CatalogFormInputLine>

            <CatalogFormInputLine
              v-if="showSerialNumber"
              ref="serialNumberInputLine"
              :formId="'serialNumber'"
              :displayLabel="$t('catalog.createModal.modalLabels.8')"
              :initialValue="dewaltUsn ? dewaltUsn.Serial : ''"
              :maxlength="50"
              :isDisabled="attemptingAddItem || attemptingAddItemAndNext"
            >
            </CatalogFormInputLine>

            <div v-if="showItemNumber">
              <NextNumberCatalogFormInputLine
                ref="itemNumberInputLine"
                :numberType="itemNumberType"
                :formId="'itemNumber'"
                :displayLabel="$t('catalog.createModal.modalLabels.9')"
                :maxlength="50"
                :isRequired="masterId !== null"
                :isDisabled="attemptingAddItem || attemptingAddItemAndNext"
                :isLoading="isLoadingNewItem"
                @onIsValidChanged="isItemNumberValid = $event"
                @getNewNumber="getNewItemNumber = $event"
                @saveUsedNumber="saveItemNumber = $event"
                @checkDuplicateNo = "checkDuplicateNo"
                :isCatalogAddItem = "true"
              >
              </NextNumberCatalogFormInputLine>
            </div>
            <NextNumberCatalogFormInputLine
              v-if="showBarcode"
              ref="barcodeInputLine"
              :numberType="barcodeNumberType"
              :formId="'barcode'"
              :displayLabel="$t('catalog.createModal.modalLabels.10')"
              :isRequired="false"
              :maxlength="50"
              :isDisabled="attemptingAddItem || attemptingAddItemAndNext"
              :isLoading="isLoadingNewItem"
              @onIsValidChanged="isBarcodeValid = $event"
              @getNewNumber="getNewBarcode = $event"
              @saveUsedNumber="saveBarcode = $event"
              @isAllowBarcodeBlank="isAllowBarcodeBlank"
              @checkDuplicateNo = "checkDuplicateNo"
              :isCatalogAddItem = "true"
            >
            </NextNumberCatalogFormInputLine>
          </form>
        </div>
        <div class="modal-footer importmodel">
          <template>
            <button
              v-if="
                attemptingAddItem ||
                attemptingAddItemAndNext ||
                attemptingImport
              "
              class="btn close-btn"
              type="button"
              disabled
            >
              {{ $t("buttons.cancel") }}
            </button>
            <button v-else type="button" class="btn close-btn" @click="close">
              {{ $t("buttons.cancel") }}
            </button>
          </template>
          <template
            v-if="itemType === 'T' && !dewaltUsn && doShowUniqueToolFields"
          >
            <tw-button
              :permissions="['ToolDetailEdit', 'MaterialDetailEdit']"
              :buttonText="
                attemptingAddItemAndNext
                  ? `${$t('buttons.addNext')}...`
                  : $t('buttons.addNext')
              "
            >
              <button
                v-if="attemptingAddItemAndNext"
                class="btn"
                type="button"
                disabled
              >
                {{ $t("buttons.addNext") }}...
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
              <button
                v-else
                @click="handleAddItemAndNext()"
                type="button"
                class="btn"
                :disabled="
                  isClicked ||
                  attemptingAddItem ||
                  duplicateModel ||
                  (dewaltUsn && dewaltUsn.DuplicateSerialExistsInOrg) ||
                  isLoadingNewItem">
                {{ $t("buttons.addNext") }}
              </button>
            </tw-button>
          </template>
          <template
            v-if="
              !newItemFromScratch &&
              masterId !== null &&
              (itemType !== 'T' || doShowUniqueToolFields)
            "
          >
            <tw-button
              :permissions="['ToolDetailEdit', 'MaterialDetailEdit']"
              :buttonText="uniqueToolBtnText"
            >
              <button
                v-if="attemptingAddItem"
                class="btn"
                type="button"
                disabled
              >
                {{ uniqueToolBtnText }}
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
              <button
                v-else
                @click="handleAddItems()"
                type="button"
                class="btn"
                v-bind:disabled="
                  isClicked ||
                  attemptingAddItemAndNext ||
                  duplicateModel ||
                  (dewaltUsn && dewaltUsn.DuplicateSerialExistsInOrg) ||
                  isLoadingNewItem">
                {{ uniqueToolBtnText }}
              </button>
            </tw-button>
          </template>

          <template v-else>
            <tw-button
              :permissions="['ToolDetailEdit', 'MaterialDetailEdit']"
              :buttonText="
                attemptingImport
                  ? `${$t('buttons.addItem')}...`
                  : $t('buttons.addItem')
              "
            >
              <button
                v-if="attemptingImport"
                class="btn"
                type="button"
                disabled
              >
                {{ $t("buttons.addItem") }}...
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
              <button
                v-else
                @click="importModel"
                type="button"
                class="btn"
                v-bind:disabled="
                  duplicateModel ||
                  (dewaltUsn && dewaltUsn.DuplicateSerialExistsInOrg) ||
                  isLoadingNewItem
                "
              >
                {{ $t("buttons.addItem") }}
              </button>
            </tw-button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import CatalogFormInputLine from './CatalogFormInputLine.vue'
import TwButton from '../components/shared/TWButton.vue'
import NextNumberCatalogFormInputLine from './NextNumberCatalogFormInputLine.vue'
import { errorHandler } from '../helpers/utils.js'
import DatePicker from '../components/shared/DatePicker.vue'
import CategoryForm from '../administration/CategoryForm.vue'
import $ from 'jquery'
import ConfirmDialog from '../components/shared/ConfirmDialog.vue'
import ManufacturersForm from '../administration/ManufacturersForm.vue'
import ItemAttachments from '../catalogs/ItemPopout/ItemAttachments.vue'

export default {
  name: 'CatalogImportModel',
  components: {
    CatalogFormInputLine,
    NextNumberCatalogFormInputLine,
    TwButton,
    DatePicker,
    CategoryForm,
    ConfirmDialog,
    ManufacturersForm,
    ItemAttachments
  },
  props: {
    catalogRecord: {
      type: Object,
      default: null
    },
    dewaltUsn: {
      type: Object,
      default: null
    },
    doCreateUniqueTool: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      isLoadingNewItem: true,
      currentStockpointId: null,
      attemptingAddItem: false,
      attemptingAddItemAndNext: false,
      attemptingImport: false,
      isLoadingCategories: false,
      isLoadingManufacturers: false,
      categories: [],
      manufacturers: [],
      duplicateModel: null,
      newModelSelectedTrackingMethod: null,
      masterId: this.catalogRecord.MasterId,
      catalogModelId: this.catalogRecord.CatalogModelId,
      catalogNumber: this.catalogRecord.CatalogNumber,
      model: this.catalogRecord.Model,
      newItemFromScratch: this.catalogRecord.newItemFromScratch !== undefined,
      description: this.catalogRecord.Description,
      manufacturer: this.catalogRecord.Manufacturer,
      upc: this.catalogRecord.Upc,
      year: this.catalogRecord.ModelYear,
      category: this.catalogRecord.Category,
      itemType: this.catalogRecord.ItemType,
      itemSubType: this.catalogRecord.ItemSubType,
      inventoryStocked: this.catalogRecord.InventoryStocked,
      saveItemNumber: null,
      saveBarcode: null,
      isItemNumberValid: false,
      isBarcodeValid: false,
      getNewBarcode: null,
      getNewItemNumber: null,
      purchaseCost: null,
      purchaseDate: null,
      formatter: new Intl.NumberFormat('en-EN', {
        style: 'currency',
        currency: 'USD'
      }),
      selectedItem: null,
      modalCount: 1,
      categoryId: null,
      confirmDlgTitle: '',
      message: '',
      manufacturerId: null,
      selectedItemForManufacturer: null,
      isCategoryForm: false,
      modalCountManufacturer: 1,
      categoryPopoupTitle: '',
      allowBarcodeBlank: false,
      isDisabledButton: false,
      isClicked: false
    }
  },
  async created () {
    this.currentStockpointId = this.globalStockpointId
    if (this.masterId === null && this.catalogModelId !== null) {
      this.getDuplicateModelData()
    }

    if (this.newItemFromScratch) {
      this.getManufacturers()
    }
    if (!this.isCategoryForm) {
      this.selectedItemForManufacturer = {
        Id: '',
        Number: '',
        Description: '',
        Supervisor: '',
        Phone: '',
        Fax: ''
      }
    }
    this.currentDateTime()
  },
  methods: {
    currentDateTime () {
      if (this.purchaseDate) {
        return this.purchaseDate
      }

      const year = new Date().getFullYear()
      const month = (1 + new Date().getMonth()).toString()
      const date = new Date().getDate().toString()
      const dateTime = month + '/' + date + '/' + year
      this.purchaseDate = dateTime
      return dateTime
    },
    costAsNumber (costVal) {
      if (typeof costVal === 'string') {
        return Number(costVal.replace(/[^0-9.]+/g, ''))
      }
      return costVal
    },
    async getDuplicateModelData () {
      if (this.model === null) {
        return
      }
      const params = JSON.stringify({
        modelName: '\'' + this.model + '\''
      })
      const { data } = await axios.post('/api/core/CatalogModelService/GetModelByName', params)
      this.duplicateModel = data.Body
    },
    async getNewItemData (itemType) {
      const isQuantityitemType = this.isQuantityTool() ||
    this.isQuantityMaterial()

      if (
        !this.newItemFromScratch &&
        isQuantityitemType &&
        this.masterId !== null
      ) {
        this.isLoadingNewItem = false
      }
      if (
        !this.newItemFromScratch &&
        !this.isUniqueTool() &&
        this.masterId !== null
      ) {
        return
      }

      this.isLoadingNewItem = true

      if (this.masterId === null && !itemType) {
        return // unknown whether tool or material yet
      }

      if (!itemType) {
        itemType = this.itemType
      } else {
        if (itemType === 'Materials & Consumables') {
          itemType = 'M'
        } else {
          itemType = 'T'
        }
      }
      this.isLoadingNewItem = false
    },
    async getCategories () {
      let categoryListType = 12
      if (this.itemType === this.$t('catalog.materials')) {
        categoryListType = 13
      }
      this.isLoadingCategories = true
      try {
        const data = await this.$store.dispatch('loadDataByListType', {
          query: '',
          listTypeId: categoryListType,
          stockpointId: this.globalStockpointId
        })
        this.categories = data.map(c => ({
          key: c.Value,
          value: c.Key
        }))
        if (this.userPermission.includes('CategoriesEdit')) {
          this.categories.unshift({
            key: this.$t('catalog.addCategory'),
            value: this.$t('catalog.addCategory')
          })
        }
      } catch (error) {
        if (error.response) {
          errorHandler(this.$toast, error.response.statusText, error)
        }
      } finally {
        this.isLoadingCategories = false
      }
    },
    async getManufacturers () {
      this.isLoadingManufacturers = true
      try {
        const data = await this.$store.dispatch('loadDataByListType', {
          query: '',
          listTypeId: 14,
          stockpointId: this.globalStockpointId
        })
        this.manufacturers = data.map(m => ({
          key: m.Value,
          value: m.Key
        }))
        if (this.userPermission.includes('ManufacturersEdit')) {
          this.manufacturers.unshift({
            key: this.$t('catalog.addManufacturer'),
            value: this.$t('catalog.addManufacturer')
          })
        }
      } catch (error) {
        if (error.response) {
          errorHandler(this.$toast, error.response.statusText, error)
        }
      } finally {
        this.isLoadingManufacturers = false
      }
    },
    onItemTypeSet (itemType) {
      this.itemType = itemType
      if (itemType === this.$t('catalog.createModal.itemType.options.1')) {
        this.newModelSelectedTrackingMethod = this.$t(
          'catalog.createModal.trackingMethod.options.1'
        )
        this.$refs.trackingMethodInputLine.lockToValue(
          this.$t('catalog.createModal.trackingMethod.options.1')
        )
      } else if (itemType === this.$t('catalog.createModal.itemType.options.2')) {
        this.newModelSelectedTrackingMethod = this.$t(
          'catalog.createModal.trackingMethod.options.0'
        )
        this.$refs.trackingMethodInputLine.lockToValue(
          this.$t('catalog.createModal.trackingMethod.options.0')
        )
      } else {
        this.$refs.trackingMethodInputLine.lockToValue(null)
      }
      this.getCategories()
      this.getNewItemData(itemType)
    },
    checkDuplicateNo (isDisabled) {
      this.isDisabledButton = isDisabled
    },
    onTrackingMethodSet (trackingMethod) {
      if (
        !this.doShowUniqueToolFields &&
        trackingMethod ===
          this.$t('catalog.createModal.trackingMethod.options.0')
      ) {
        this.$refs.itemNumberInputLine.update(null)
        this.$refs.barcodeInputLine.update(null)
        if (this.$refs.serialNumberInputLine) {
          this.$refs.serialNumberInputLine.update(null)
        }
      }
      this.newModelSelectedTrackingMethod = trackingMethod
    },
    async addItemAndNext () {
      setTimeout(async () => {
        if (!this.checkForm()) return
        this.attemptingAddItemAndNext = true
        const formData = new FormData(this.$refs.importModelForm)
        const data = [...formData.entries()]
        const formList = []
        data.forEach(item => {
          const key = item[0] === 'csp' ? 'CurrentStockPoint' : item[0]
          const value = item[0] === 'purchaseCost' ? this.costAsNumber(item[1]) : item[1]
          formList.push({ [key]: value })
        })
        formList.push({ purchaseDate: this.purchaseDate })
        var key = 'Quantity'
        formList.push({ [key]: 1 })
        const formDataList = formList.reduce(function (result, item) {
          var key = Object.keys(item)[0]
          result[key] = item[key]
          return result
        },
        {})
        const params = JSON.stringify({ newTool: JSON.stringify(formDataList) })
        try {
          await axios.post('/api/core/ToolsService/AddItem', params)
          this.getNewItemData()
          this.$refs.serialNumberInputLine.update('')
          if (this.itemType === 'T') {
            this.catalogRecord.BrowserViewCount++
          } else {
            this.catalogRecord.InventoryStocked =
              !this.catalogRecord.InventoryStocked
          }
          if (this.saveItemNumber) {
            await this.saveItemNumber()
          }
          if (this.saveBarcode) {
            await this.saveBarcode()
          }
          if (this.getNewItemNumber) {
            await this.getNewItemNumber()
          }
          if (this.getNewBarcode) {
            await this.getNewBarcode()
          }
          this.attemptingAddItemAndNext = false
          this.isClicked = false
          this.$emit('importSuccess', this.successToastMessage(), false)
        } catch (error) {
          this.attemptingAddItemAndNext = false
          if (error.response) {
            errorHandler(this.$toast, error.response.statusText, error)
          }
        }
      }, 2500)
    },
    async addItem () {
      setTimeout(async () => {
        if (!this.checkForm()) return
        this.attemptingAddItem = true
        const formData = new FormData(this.$refs.importModelForm)
        const data = [...formData.entries()]
        const formList = []
        data.forEach(item => {
          const key = item[0] === 'csp' ? 'CurrentStockPoint' : item[0]
          const value = item[0] === 'purchaseCost' ? this.costAsNumber(item[1]) : item[1]
          formList.push({ [key]: value })
        })
        formList.push({ purchaseDate: this.purchaseDate })
        var key = 'Quantity'
        formList.push({ [key]: 1 })
        const formDataList = formList.reduce(function (result, item) {
          var key = Object.keys(item)[0]
          result[key] = item[key]
          return result
        },
        {})
        const params = JSON.stringify({ newTool: JSON.stringify(formDataList) })
        try {
          await axios.post('/api/core/ToolsService/AddItem', params)
          if (this.itemType === 'T') {
            this.catalogRecord.BrowserViewCount++
          } else {
            this.catalogRecord.InventoryStocked =
              !this.catalogRecord.InventoryStocked
          }
          if (this.saveItemNumber) {
            await this.saveItemNumber()
          }
          if (this.saveBarcode) {
            await this.saveBarcode()
          }
          this.attemptingAddItem = false
          this.$emit('importSuccess', this.successToastMessage(), true)
        } catch (error) {
          this.attemptingAddItem = false
          if (error.response) {
            errorHandler(this.$toast, error.response.statusText, error)
          }
        }
      }, 2500)
    },
    async importModel () {
      if (!this.checkForm()) return
      this.attemptingImport = true
      const form = this.$refs.importModelForm
      const formData = new FormData(form)
      const lstSubTypes = []
      let itemType = this.itemType === this.$t('catalog.createModal.itemType.options.0') ||
       this.itemType === this.$t('catalog.createModal.itemType.options.2')
        ? 'T'
        : 'M'
      if (itemType === 'T' &&
        formData.get('trackingMethod') === this.$t('catalog.createModal.trackingMethod.options.1')) {
        itemType = 'Q'
      }
      if (itemType === 'T' &&
        formData.get('trackingMethod') === this.$t('catalog.createModal.trackingMethod.options.0') &&
         this.itemType === this.$t('catalog.createModal.itemType.options.2')) {
        lstSubTypes.push({ Code: 'E', IsDelete: false })
      }
      let unitOfMeasureId = null
      if (this.orgSettingsUomId) {
        unitOfMeasureId = this.orgSettingsUomId
      }

      const importParams = JSON.stringify({
        importModelDto: JSON.stringify({
          CatalogModelId: this.catalogModelId,
          CatalogNumber: this.catalogNumber,
          ModelName: formData.get('modelName'),
          ModelDescription: formData.get('description'),
          ModelUpc: this.upc,
          ModelYear: this.year,
          CategoryId: this.categoryId,
          ManufacturerId: this.manufacturerId,
          ManufacturerName: this.manufacturer,
          ItemType: itemType,
          ItemNumber: formData.get('itemNumber'),
          Barcode: formData.get('barcode'),
          MasterSubTypeDtos: lstSubTypes,
          UnitOfMeasureId: unitOfMeasureId
        }),
        allowBarcodeBlank: this.allowBarcodeBlank ? this.allowBarcodeBlank : false
      })
      try {
        const { data: { Body: model } } = await axios.post('/api/core/CatalogModelService/ImportModel', importParams)
        if (this.newItemFromScratch) {
          this.$emit('importSuccess', '', true)
          this.$emit('newItemFromScratch', model)
          if (this.saveItemNumber) {
            await this.saveItemNumber()
          }
          if (this.saveBarcode) {
            await this.saveBarcode()
          }
          this.itemType = 'A'
        } else {
          this.catalogRecord.MasterId = model.MasterId
          this.catalogRecord.Description = model.Description
          this.catalogRecord.ItemType = model.ItemType
          this.catalogRecord.Category = model.Category
          this.catalogRecord.BrowserViewCount = model.BrowserViewCount
          this.catalogRecord.InventoryStocked = model.InventoryStocked
          this.catalogRecord.InOrgModel = model.InOrgModel
          this.$emit('importSuccess', this.successToastMessage(), true)
        }
        this.saveModelSystemNumber(model.InOrgModel.MasterNumber, 15, 1)
      } catch (error) {
        if (error.response) {
          errorHandler(this.$toast, error.response.statusText, error)
        }
      } finally {
        this.attemptingImport = false
      }
    },
    async saveModelSystemNumber (idNumber, numType, numSource) {
      const params = JSON.stringify({
        number: '\'' + idNumber + '\'',
        numberType: '\'' + numType + '\'',
        numberSource: '\'' + numSource + '\''
      })
      await axios.post('/api/core/nextnumberservice/setlastnumber', params)
    },
    checkForm () {
      if (this.showItemNumber && this.$refs.itemNumberInputLine.$v.$invalid) {
        this.$refs.itemNumberInputLine.$v.value.$touch()
        return
      }
      if (this.showBarcode && this.$refs.barcodeInputLine.$v.$invalid) {
        this.$refs.barcodeInputLine.$v.value.$touch()
        return
      }
      const form = this.$refs.importModelForm
      if (this.newItemFromScratch) {
        return (
          form.itemType.value &&
          form.trackingMethod.value &&
          form.manufacturer.value &&
          form.category.value &&
          form.modelName.value &&
          form.description.value &&
          this.isNewItemValid(form)
        )
      } else {
        if (this.masterId !== null) {
          if (this.itemType === 'T' && this.doShowUniqueToolFields) {
            return this.isItemNumberValid && this.isBarcodeValid
          }
        } else if (this.catalogModelId !== null) {
          return (
            form.itemType.value &&
            form.trackingMethod.value &&
            form.category.value &&
            form.description.value
          )
        }
      }
      return true
    },
    successToastMessage: function () {
      const itemNumber = this.$refs.importModelForm.itemNumber
        ? this.$refs.importModelForm.itemNumber.value
        : ''

      let specificItemMessage = ''
      if (itemNumber) {
        specificItemMessage = ', #' + itemNumber
      }

      let actionMessage = 'created'
      if (this.itemType === 'M' || this.itemType === 'Q') {
        if (this.catalogRecord.InventoryStocked) {
          actionMessage = 'stocked'
        } else {
          actionMessage = 'unstocked'
        }
      }

      return (
        [this.manufacturer, this.model].filter((x) => x).join(' ') +
        specificItemMessage +
        ' ' +
        actionMessage +
        ' successfully!'
      )
    },
    close () {
      this.$emit('hideModal')
    },
    isNewItemValid (form) {
      if (
        form.trackingMethod.value ===
        this.$t('catalog.createModal.trackingMethod.options.1')
      ) {
        return this.isItemNumberValid
      } else {
        return true
      }
    },
    onCategorySelected (catId) {
      if (catId !== this.$t('catalog.addCategory')) {
        this.categoryId = catId
        return
      }
      this.selectedItem = {
        description: '',
        categoryNumber: '',
        type: this.itemType === this.$t('catalog.materials') ? 'M'
          : this.itemType === this.$t('catalog.createModal.itemType.options.0') ||
           this.$t('catalog.createModal.itemType.options.2') ? 'T' : 'A'
      }
      this.modalCount++
      switch (this.itemType) {
        case this.$t('catalog.createModal.itemType.options.0'):
          this.categoryPopoupTitle = this.$t('admin.categories.categoryTypeTitles.0')
          break
        case this.$t('catalog.createModal.itemType.options.1'):
          this.categoryPopoupTitle = this.$t('admin.categories.categoryTypeTitles.1')
          break
        default:
          break
      }
      this.showCategoryForm()
    },
    showCategoryForm () {
      this.$nextTick(() => {
        $('#categoryForm').modal('show')
      })
    },
    async onSaveCategorySuccess (catId) {
      $('#categoryForm').modal('hide')
      this.categoryId = catId
      await this.getCategories()
      this.$refs.categoryInputLine.update(this.categoryId)
    },
    onSaveCategoryFailure () {
      $('#categoryForm').modal('hide')
    },
    closeCategoryForm () {
      $('#categoryForm').modal('hide')
      this.categoryId = null
      this.$refs.categoryInputLine.update(this.categoryId)
    },
    onCancelAddCategory () {
      this.categoryId = null
      this.$refs.categoryInputLine.update(this.categoryId)
    },
    onManufacturerSelected (manId) {
      if (manId !== this.$t('catalog.addManufacturer')) {
        this.manufacturerId = manId
        return
      }
      this.selectedItemForManufacturer = {
        name: null,
        manufacturerNumber: '',
        phone: null,
        fax: null,
        url: null,
        upcPrefix: null
      }
      this.modalCountManufacturer++
      this.showManufacturerForm()
    },
    showManufacturerForm () {
      this.$nextTick(() => {
        $('#manufacturersForm').modal('show')
      })
    },
    async onSaveManufacturerSuccess (manId) {
      $('#manufacturersForm').modal('hide')
      this.manufacturerId = manId
      await this.getManufacturers()
      this.$refs.manufacturerInputLine.update(this.manufacturerId)
    },
    closeManufacturerForm () {
      $('#manufacturersForm').modal('hide')
      this.manufacturerId = null
      this.$refs.manufacturerInputLine.update(this.manufacturerId)
    },
    openConfirmSaveClose (isCatForm) {
      this.isCategoryForm = isCatForm
      this.openConfirmDlg()
    },
    openConfirmDlg () {
      this.confirmDlgTitle = this.$t('buttons.save.default') + '?'
      this.message = this.$t('message.warning.confirm')
      this.$refs.confirmSaveClose.open()
    },
    onConfirmSaveClose (confirm) {
      if (confirm) {
        if (this.isCategoryForm) {
          this.$refs.categoryForm.submit()
        } else {
          this.$refs.manufacturersForm.submit()
        }
      } else {
        if (this.isCategoryForm) {
          this.closeCategoryForm()
        } else {
          this.closeManufacturerForm()
        }
      }
      this.isCategoryForm = false
    },
    isUniqueTool () {
      return this.itemType === 'T'
    },
    isQuantityTool () {
      return this.itemType === 'Q'
    },
    isQuantityMaterial () {
      return this.itemType === 'M'
    },
    isAllowBarcodeBlank (val) {
      this.allowBarcodeBlank = val
    },
    handleAddItems () {
      this.isClicked = true
      this.addItem()
    },
    handleAddItemAndNext () {
      this.isClicked = true
      this.addItemAndNext()
    }
  },
  computed: {
    globalStockpointId () {
      return this.$store.getters.getStockpointId
    },
    doShowUniqueToolFields () {
      if (
        this.newModelSelectedTrackingMethod ===
          this.$t('catalog.createModal.trackingMethod.options.0') &&
        !this.doCreateUniqueTool
      ) {
        return false
      }

      return true
    },
    isDisabled () {
      return this.isDisabledButton
    },
    itemNumberType () {
      if (this.itemType == 'A') {
        return null
      } else if (this.itemType == 'T' ||
        this.itemType == 'Tools & Equipment' ||
        this.itemType == 'Tool' ||
        this.itemType == 'Q') {
        return 12
      } else if (this.itemType === this.$t('catalog.materials')) {
        return 17
      } else {
        return 17
      }
    },
    barcodeNumberType () {
      // barcodes are always type 1
      return 1
    },
    isExistingItem () {
      return !this.newItemFromScratch && this.masterId !== null
    },
    isExistingUniqueTool () {
      return this.isExistingItem && this.itemType === 'T'
    },
    showSerialNumber () {
      return this.isExistingUniqueTool
    },
    showItemNumber () {
      return (
        this.itemNumberType &&
        (this.isExistingItem ||
          this.newModelSelectedTrackingMethod ===
            this.$t('catalog.createModal.trackingMethod.options.1'))
      )
    },
    showBarcode () {
      return (
        this.isExistingItem ||
        this.newModelSelectedTrackingMethod ===
          this.$t('catalog.createModal.trackingMethod.options.1')
      )
    },
    uniqueToolBtnText () {
      if (this.attemptingAddItem) {
        return this.itemType === 'M' || this.itemType === 'Q'
          ? this.inventoryStocked
            ? `${this.$t('buttons.unstocking')}...`
            : `${this.$t('buttons.stocking')}...`
          : `${this.$t('buttons.add')}...`
      } else {
        return this.itemType === 'M' || this.itemType === 'Q'
          ? this.inventoryStocked
            ? this.$t('buttons.stopStock')
            : this.$t('buttons.startStock')
          : this.$t('buttons.add')
      }
    },
    numberType () {
      if (this.itemType === 'T' || this.itemType === this.$t('catalog.tools')) {
        return 14
      } else if (this.itemType === 'M' || this.itemType === this.$t('catalog.materials')) {
        return 17
      } else {
        return 22
      }
    },
    userPermission () {
      return this.$store.getters.userPermission
    },
    orgSettingsUomId () {
      return this.$store.getters.orgSettings.UomId
    },
    itemSubTypeCheck () {
      if (this.catalogRecord.ItemType === 'M') {
        return 'Materials & Consumables'
      } else if (this.catalogRecord.MasterSubTypeDtos != null &&
        this.catalogRecord.MasterSubTypeDtos.length &&
        this.catalogRecord.MasterSubTypeDtos[0].Code === 'E') {
        return 'Equipment'
      } else {
        return 'Tool'
      }
    }
  }
}
</script>
