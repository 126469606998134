import axios from 'axios'
import store from '../store/index'
import XLSX from 'xlsx'
import { helpers } from 'vuelidate/lib/validators'
import { uuid } from 'vue-uuid'
import routeJson from '../locales/en/routes.js'
import { PICK_STATUS } from './const'
import { cloneDeep } from 'lodash'
import { datadogRum } from '@datadog/browser-rum'
import * as Sentry from '@sentry/vue'
import { i18n } from '../i18n'
import $ from 'jquery'

export function generateUniqueId (length = 16) {
  let uid = ''; const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  for (let i = 0; i < (length); i++) {
    uid += chars.charAt(Math.floor(Math.random() * chars.length))
  }
  return uid
}

// formats currency as USD
export function formatCurrency (cost) {
  const formatter = new Intl.NumberFormat('en-EN', { style: 'currency', currency: 'USD' })
  if (typeof cost === 'string') {
    cost = Number(cost.replace(/[^0-9.]+/g, ''))
  }
  return formatter.format(cost)
}

export function processFileDownload (body, filename, mimeType, view) {
  const blob = new Blob([body], { type: mimeType })
  const fileName = `${filename}`
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, fileName) // IE 10+
  } else {
    const url = URL.createObjectURL(blob)
    if (view) {
      window.open(url, '_blank')
    } else {
      const link = document.createElement('a')
      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        link.setAttribute('href', url)
        link.setAttribute('download', fileName)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(link)
      }
    }
  }
}

export function capitalizeFirstLetter (s) {
  if (s) {
    if (typeof s !== 'string') {
      s = s.toString()
    }
    return s.charAt(0).toUpperCase() + s.slice(1)
  } else {
    return ''
  }
}

export function stringIsNullUndefinedOrWhiteSpace (s) {
  return s === null || s === undefined || s.trim().length === 0
}

export const isAuthenticated = async () => {
  if (store.getters.isLoggedIn) return true
  try {
    const { data, status } = await axios.post('/api/core/SessionsService/Self', { })
    if (status === 200 && data.Body.OrgId) {
      localStorage.setItem('OrgId', data.Body.OrgId)
      localStorage.setItem('UserId', data.Body.UserId)
      localStorage.setItem('SessionId', data.Body.SessionId)
      store.commit('setAssignedToId', data.Body.AssignedToId)
      store.commit('setIsSuperUser', data.Body.IsImpersonation)
      store.commit('setStockpointId', data.Body.PinnedLocationId)
      store.commit('setUser', {
        fname: data.Body.FirstName, lname: data.Body.LastName, uname: data.Body.UserName
      })
      datadogRum.setUser({
        OrgId: localStorage.getItem('OrgId'),
        UserId: localStorage.getItem('UserId'),
        User: data.Body.FirstName + ' ' + data.Body.LastName,
        SessionId: localStorage.getItem('SessionId'),
        OrgName: localStorage.getItem('OrgName'),
        id: localStorage.getItem('UserId'),
        name: data.Body.FirstName + ' ' + data.Body.LastName,
        email: data.Body.UserName,
        plan: localStorage.getItem('OrgId')
      })
      Sentry.setUser({
        email: data.Body.UserName,
        id: data.Body.UserId,
        username: data.Body.FirstName + ' ' + data.Body.LastName,
        orgId: localStorage.getItem('OrgId')
        // It would be interesting to be able to set cluster here
        // We might see different user misery by cluster and user geo
      })
      Sentry.setTag('OrgId', localStorage.getItem('OrgId'))
      if (process.env.VUE_APP_SEGMENT_ENABLED == 'true') {
        window.analytics.identify(data.Body.UserId, {
          name: data.Body.FirstName + ' ' + data.Body.LastName,
          orgId: localStorage.getItem('OrgId'),
          email: data.Body.UserName
        })
      }
    }
  } catch (error) {
    return false
  }

  const params = JSON.stringify({
    orgId: '\'' + localStorage.getItem('OrgId') + '\''
  })
  const paramsForEntitlements = JSON.stringify({
    licenseSource: '0' // desktop
  })
  const headerConfig = {
    headers: {
      'Content-type': 'application/json; charset=utf-8'
    }
  }
  try {
    // Need to first get flags so we know which Permission url to use
    const flagRes = await axios.post('/api/core/FlagsService/GetFlagValues', params, headerConfig)
    const res = await Promise.all([
      axios.post(
        `/api/core/UserPermissionsService/${flagRes.data.Body.ShowPlatformRolesAndPermissions === 'true'
          ? 'GetPlatformUserEntitledPermissionsList'
          : 'GetUserEntitledPermissionsList'}`,
        {}),
      store.dispatch('loadCart', true).then(() => {
        store.dispatch('loadReservationCart', false)
      }),
      axios.post('/api/core/NextNumberService/IsAllowUserNumbers', params, headerConfig),
      axios.get('/api/cloud/orgSettings/'),
      axios.post('/api/core/UserEntitlementsService/GetEntitlementsView', paramsForEntitlements, headerConfig),
      axios.post('/api/core/OrgsService/FindOrgById', params),
      store.dispatch('loadThemes'),
      axios.post('/api/core/OrgAccountsService/GetOrgAccount', {}),
      axios.post('/api/core/ChannelsService/GetChannelOrg', {}),
      axios.post('/api/core/FlagsService/GetOrgFlagOverrides', {})
    ])
    const { config, request } = res[0]
    const { url } = config
    const { responseURL } = request
    axios.defaults.params = {
      jobId: store.getters.getJobId
    }
    if (responseURL.includes(url)) {
      store.commit('setLoggedIn', true)
      const flag = localStorage.getItem('jobSiteUser') ? Number(localStorage.getItem('jobSiteUser')) : 0
      store.commit('setJobSiteUser', flag)
      store.commit('setUserPermissions', res[0].data.Body)
      store.commit('setShowCloudWorkInProgress', flagRes.data.Body.ShowCloudWorkInProgress === 'true')
      store.commit('setOwnerIsRootForQuantityTools', flagRes.data.Body.OwnerIsRootForQuantityTools === 'true')
      store.commit('setShowCloudReservations', flagRes.data.Body.ShowCloudReservations === 'true')
      store.commit('setShowCloudScheduleBackOffice', flagRes.data.Body.ShowCloudScheduleBackOffice === 'true')
      store.commit('setShowCloudScheduleWarehouse', flagRes.data.Body.ShowCloudScheduleWarehouse === 'true')
      store.commit('setShowReportCustomDashboard', flagRes.data.Body.ShowCustomDashboards === 'true')
      store.commit('setShowTasks', flagRes.data.Body.ShowTasks === 'true')
      store.commit('setViewGoogleMap', flagRes.data.Body.ViewGoogleMap === 'true')
      store.commit('setShowUsersInAdmin', flagRes.data.Body.ShowUsersInAdmin === 'true')
      store.commit('setShowSafetyMenu', flagRes.data.Body.ShowSafetyMenu === 'true')
      store.commit('setAllowUserNumbers', res[2].data.Body)
      store.commit('setOrgSettings', res[3].data)
      store.commit('setEntitlements', res[4].data.Body.Entitlements)
      localStorage.setItem('OrgName', res[5].data.Body.Name)
      store.commit('setIsSandboxUser', res[5].data.Body.IsProduction !== true)
      store.commit('setShowFuelCards', flagRes.data.Body.ShowFuelCards === 'true')
      store.commit('setShowCollaborate', flagRes.data.Body.ShowCollaborate === 'true')
      store.commit('setShowDocumentCentral', flagRes.data.Body.ShowDocumentCentral === 'true')
      store.commit('setSafetyAccountId', res[7].data.Body ? res[7].data.Body.SafetyAccountId : null)
      store.commit('setShowScheduling', flagRes.data.Body.ShowScheduling.toLowerCase() === 'true')
      store.commit('setIsEHSOnly', res[4].data.Body.Entitlements.includes('SafetyTierMaxAdmins') && !res[4].data.Body.Entitlements.includes('T_And_E'))
      store.commit('setShowInsights', flagRes.data.Body.ShowInsights === 'true')
      store.commit('setShowNotification', flagRes.data.Body.ShowMessageCenter === 'true')
      store.commit('setShowEquipmentLeaseRentals', flagRes.data.Body.ShowEquipmentLeaseRentals === 'true')
      store.commit('setShowPlatformNavigation', flagRes.data.Body.ShowPlatformNavigation === 'true')
      store.commit('setShowRequisitionsV2', flagRes.data.Body.ShowRequisitionsV2 === 'true')
      store.commit('setHasPredictLossModel', flagRes.data.Body.ActivePredictLossModel.length > 0)
      store.commit('setShowSafetyPlan', flagRes.data.Body.ShowSafetyPlan === 'true')
      store.commit('SetShowEnhancedWorkflows', flagRes.data.Body.ShowEnhancedWorkflows === 'true')
      store.commit('SetShowWorkflowApprovals', flagRes.data.Body.ShowWorkflowApprovals === 'true')
      store.commit('setShowPlatformRolesAndPermissions', flagRes.data.Body.ShowPlatformRolesAndPermissions === 'true')
      store.commit('setItemBrowserMaxRowsCount', parseInt(flagRes.data.Body.ItemBrowserMaxRowsCount))
      store.commit('setWorkOrderMaxRowsCount', parseInt(flagRes.data.Body.WorkOrderMaxRowsCount))
      store.commit('setShowEquipmentNav', flagRes.data.Body.EquipmentNav === 'true')
      store.commit('setShowServiceRequestNotification', flagRes.data.Body.ServiceRequestNotification === 'true')
      store.commit('setGpsSupport', flagRes.data.Body.GpsSupport === 'true')
      store.commit('setCustomReportingEnhancements', flagRes.data.Body.CustomReportingEnhancements === 'true')
      store.commit('setShowRequisitionBrowser', flagRes.data.Body.RequisitionBrowser === 'true')
      store.commit('setShowTasksWorkflowsFollowOn', flagRes.data.Body.TasksWorkflowsFollowOn === 'true')
      store.commit('setShowPurchaseOrderItemBrowser', flagRes.data.Body.PurchaseOrderItemBrowser === 'true')
      store.commit('setShowIntercom', flagRes.data.Body.ShowIntercom === 'true')
      store.commit('setShowRequisitionsImprovements', flagRes.data.Body.ShowRequisitionsImprovements === 'true')
      store.commit('setShowWFM', flagRes.data.Body.ShowWFM === 'true')
      store.commit('setShowPickedItemState', flagRes.data.Body.PickedItemState === 'true')
      store.commit('setEHSCustomReports', flagRes.data.Body.EHSCustomReports === 'true')
      store.commit('setShowJobStock', flagRes.data.Body.ShowJobStock === 'true')
      store.commit('SetSupportRedesign', flagRes.data.Body.SupportRedesign === 'true')
      store.commit('setChannelName', res[8].data.Body.ChannelName)
      store.commit('setOrgSpecificFlagValues', res[9].data.Body)
      store.commit('SetShowCollaborateExpansion', flagRes.data.Body.ShowCollaborateExpansion === 'true')
      store.commit('setShowInvoiceComments', flagRes.data.Body.ShowInvoiceComments === 'true')
      store.commit('setShowMapUpdates', flagRes.data.Body.ShowMapUpdates === 'true')
      store.commit('setAlignOpsRebrand', flagRes.data.Body.AlignOpsRebrand === 'true')
      store.commit('setAppSwitcher', flagRes.data.Body.AppSwitcher === 'true')
      store.commit('setShowSoftDeleteTemplates', flagRes.data.Body.ShowSoftDeleteTemplates === 'true')
      store.commit('setAutoRefreshDashboards', flagRes.data.Body.AutoloadDashboard === 'true')
      store.commit('setLinkSafetyUsers', flagRes.data.Body.LinkSafetyUsers === 'true')
      store.commit('setUseNewTransferPage', flagRes.data.Body.UseNewTransferPage === 'true')
      return true
    }
    return false
  } catch (error) {
    return false
  }
}

export const setOrgDetails = async () => {
  try {
    const res = await Promise.all([
      axios.get('/api/cloud/orgSettings/')
    ])
    store.commit('setOrgSettings', res[0].data)
    return true
  } catch (error) {
    return false
  }
}

export function clearLocalStorage () {
  localStorage.removeItem('OrgId')
  localStorage.removeItem('UserId')
  localStorage.removeItem('jobSiteUser')
  localStorage.removeItem('SessionId')
  localStorage.removeItem('OrgName')
  clearTransferDraft()
  clearPickDraft()
  clearPODraft()
  clearSchedulingDraft()
  clearCatalogSearchRetain()
  clearItemBrowserSearchRetain()
  clearAllSearchFilter()
}

export function clearAllSearchFilter () {
  localStorage.removeItem('column-filter')
  localStorage.removeItem('search-filter')
  localStorage.removeItem('itemViewSwitchChanged')
}

export function clearCatalogSearchRetain () {
  localStorage.removeItem('catalog-reset-url')
  localStorage.removeItem('catalog-latest-url')
  localStorage.removeItem('catalog-isShowOnlyTracked')
  localStorage.removeItem('catalog-searchText')
  localStorage.removeItem('catalog-isGrid')
  localStorage.removeItem('catalog-AllCategories-tableFilters')
  localStorage.removeItem('catalog-Tools-tableFilters')
  localStorage.removeItem('catalog-Materials-tableFilters')
  localStorage.removeItem('catalog-A-latest-url')
  localStorage.removeItem('catalog-T-latest-url')
  localStorage.removeItem('catalog-M-latest-url')
  localStorage.removeItem('catalog-A-categorychecked')
  localStorage.removeItem('catalog-T-categorychecked')
  localStorage.removeItem('catalog-M-categorychecked')
  localStorage.removeItem('catalog-A-brandchecked')
  localStorage.removeItem('catalog-T-brandchecked')
  localStorage.removeItem('catalog-M-brandchecked')
}

export function clearItemBrowserSearchRetain () {
  localStorage.removeItem('ib-showAdvancedSearch')
  localStorage.removeItem('ib-selectedCategoryIds')
  localStorage.removeItem('ib-latest-url')
  localStorage.removeItem('ib-assetKind')
  localStorage.removeItem('ib-tools-tableFilters')
  localStorage.removeItem('ib-materials-tableFilters')
  localStorage.removeItem('ib-T-latest-url')
  localStorage.removeItem('ib-M-latest-url')
  localStorage.removeItem('ib-T-isAllChecked')
  localStorage.removeItem('ib-M-isAllChecked')
  localStorage.removeItem('ib-T-search-data')
  localStorage.removeItem('ib-M-search-data')
  localStorage.removeItem('ib-T-isFullyAllocated')
  localStorage.removeItem('ib-M-isFullyAllocated')
}

export function clearTransferDraft () {
  localStorage.removeItem('transferDataWrapper')
  localStorage.removeItem('isTransfer')
  localStorage.removeItem('isPrintTransfer')
  localStorage.removeItem('isKeepPageOpen')
  localStorage.removeItem('transferedSelectedItems')
  localStorage.removeItem('editTransferDataWrapper')
  localStorage.removeItem('editTransferedSelectedItems')
  localStorage.removeItem('isSetExpressCheckInData')
  localStorage.removeItem('transferFromId')
}

export function clearPickDraft () {
  localStorage.removeItem('pickDataWrapper')
  localStorage.removeItem('pickedSelectedItems')
}

export function clearPODraft () {
  localStorage.removeItem('poDataWrapper')
  localStorage.removeItem('poSelectedItems')
  localStorage.removeItem('poSelectedDraftItems')
}

export function clearSchedulingDraft () {
  localStorage.removeItem('SchedulingCartObject')
}

export function setJobId (cart) {
  if (cart != null && cart.ShipToEntityId != null) {
    store.commit('setJobId', cart.ShipToEntityId)
  }
}

export function formData (data) {
  const params = new URLSearchParams()
  Object.entries(data).forEach(x => {
    params.append(x[0], x[1])
  })
  return params
}

export const uniq = (array, callback) => array.filter((item, i, arr) =>
  !arr.some((v, ii) => ii < i && callback(v) == callback(item))
)

export const uniqIfNotNull = (array, callback) => array.filter((item, i, arr) =>
  !arr.some((v, ii) => ii < i && callback(v) !== null && callback(v) == callback(item))
)

export const uniqIfNotNullWithKeepingIncQty = (array, callback) => array.filter((item, i, arr) =>
  !arr.some((v, ii) => {
    if (ii < i && callback(v) !== null && callback(v) == callback(item)) {
      if (v.QuantityOrdered && item.QuantityOrdered) {
        v.QuantityOrdered += item.QuantityOrdered
      }
      return true
    }
    return false
  })
)

export const errorHandler = (toaster, msg, error = null) => {
  let displayText = msg
  if (error?.response?.data?.ExceptionTypeName &&
    error?.response?.data?.ExceptionTypeName === 'PermissionDeniedException') {
    displayText = error?.response?.data?.ExceptionMessage
  }
  const errorHtml =
  `<div>
    <p id="toggleErrorDetailsButton">${displayText}</p>
  </div>`

  if (toaster) {
    toaster.error(errorHtml, {
      position: 'top',
      dismissible: false,
      duration: 5000
    })
  }

  if (error?.response?.data) {
    document.getElementById('toggleErrorDetailsButton').addEventListener('click', async function () {
      try {
        await navigator.clipboard.writeText(JSON.stringify(error?.response?.data))
        console.log('Content copied to clipboard')
      } catch {
        console.log('Failed to copy to clipboard')
      }
    })
  }
}

export const successHandler = (toaster, msg) => {
  toaster.success(msg, {
    position: 'top'
  })
}

/**
 *
 * @param {Date} date
 * @returns {String} ex: 2021-05-07
 */
export function getFormatedDateString (date = new Date(), month = 1) {
  // month = 0: last month, month = 1: current month
  date.setMonth(date.getMonth() - 1 + month)
  const yyyy = String(date.getFullYear()).padStart(4, '0')
  const mm = String(date.getMonth() + 1).padStart(2, '0')
  const dd = String(date.getDate()).padStart(2, '0')
  return yyyy + '-' + mm + '-' + dd
}

/**
 *
 * @param {Date} date
 * @returns {String} ex: 20231205
 */
export function getFormatedDateNumber (date = new Date(), month = 1) {
  // month = 0: last month, month = 1: current month
  date.setMonth(date.getMonth() - 1 + month)
  const yyyy = String(date.getFullYear()).padStart(4, '0')
  const mm = String(date.getMonth() + 1).padStart(2, '0')
  const dd = String(date.getDate()).padStart(2, '0')
  return yyyy + '' + mm + '' + dd
}

/**
 *
 * @param {R} colorCode ex: 0
 * @param {G} colorCode ex: 220
 * @param {B} colorCode ex: 0
 * @returns {String} ex: #d29735
 */
export function getFormatedColorRBGTohex (r, g, b) {
  return '#' + ((1 << 24) + (parseInt(r) << 16) + (parseInt(g) << 8) + parseInt(b)).toString(16).slice(1)
}

/**
 * @param {ColorPalette} colorPalette ex: 02200
 * @returns {String} ex: #d29735
 */
export function getFormatedColorRBGColor (ColorPalette) {
  const colorCode = ColorPalette.split(',')
  if (colorCode.length === 3) {
    return getFormatedColorRBGTohex(colorCode[0], colorCode[1], colorCode[2])
  }
}
/**
 * @param {CSSRootKey} cssRootkey ex: --secondary-color
 * @param {ColorCode} colorCode ex: ##D09433
 */
export function setCssColors (cssRootkey, colorCode) {
  document.documentElement.style.setProperty(cssRootkey, colorCode) // Reflecting throughout application
}

export function getLocaleTimestampFromUtc (utc) {
  const date = new Date(utc)
  // get local timezone offset in milliseconds
  const localOffset = date.getTimezoneOffset() * 60000 // returns minutes
  // Adjust date to local timezone
  const localDate = new Date(date.getTime() - localOffset)
  // final result while preserving the local timezone
  const resultLocalDate = new Date(0)
  resultLocalDate.setUTCFullYear(
    localDate.getUTCFullYear(),
    localDate.getUTCMonth(),
    localDate.getUTCDate()
  )
  resultLocalDate.setUTCHours(
    localDate.getUTCHours(),
    localDate.getUTCMinutes(),
    localDate.getUTCSeconds(),
    localDate.getUTCMilliseconds()
  )
  return resultLocalDate
}

/**
 *
 * @param {Date} date input date
 * @returns {String} ex: 2015-12-25T23:59:59
 */
export function getDateAndTimeString (date = new Date()) {
  const dateString = getFormatedDateString(date)
  const hour = String(date.getHours()).padStart(2, '0')
  const minute = String(date.getMinutes()).padStart(2, '0')
  const second = String(date.getSeconds()).padStart(2, '0')
  return dateString + 'T' + hour + ':' + minute + ':' + second
}

/**
 *
 * @param {Date} date
 * @returns {String} ex: 5/17/2021
 */
export function getFormatedDateString2 (inputDate = new Date()) {
  const date = new Date(inputDate)
  const yyyy = date.getFullYear()
  const m = 1 + date.getMonth()
  const d = date.getDate()
  return m + '/' + d + '/' + yyyy
}

export function getLocaleDateAndTimeString (inputDate = new Date()) {
  const date = new Date(inputDate)
  const datePart = date.toLocaleDateString()
  const timePart = date.toLocaleTimeString()
  return datePart + ', ' + timePart
}

/**
 *
 * @param {Date} date
 * @param {String} locale 'es'/'en
 * @returns {String}
 *    es: 27/05/2021 01:05 AM
 *    en: 05/27/2021 01:05 PM
 */
export function getFormattedDate (date = new Date(), locale = 'en') {
  const newDate = new Date(date)
  const yyyy = newDate.getFullYear()
  const mm = String(1 + newDate.getMonth()).padStart(2, '0')
  const dd = String(newDate.getDate()).padStart(2, '0')
  const hour = newDate.getHours() <= 12 ? newDate.getHours() : newDate.getHours() - 12
  const minute = newDate.getMinutes()
  const DN = newDate.getHours() < 12 ? 'AM' : 'PM'
  return locale === 'es'
    ? (dd + '/' + mm + '/' + yyyy + ' ' +
  (hour < 10 ? '0' + hour : hour) + ':' + (minute < 10 ? '0' + minute : minute) + ' ' + DN)
    : (mm + '/' + dd + '/' + yyyy + ' ' +
  (hour < 10 ? '0' + hour : hour) + ':' + (minute < 10 ? '0' + minute : minute) + ' ' + DN)
}

/**
 *
 * @param {Date} date
 * @param {String} locale 'es'/'en
 * @returns {String}
 *    es: 27/05/2021 1:5:10 AM
 *    en: 05/27/2021 1:5:10 PM
 */
export function getFormattedFullDate (date = new Date(), locale = 'en') {
  const newDate = new Date(date)
  const yyyy = newDate.getFullYear()
  const mm = String(1 + newDate.getMonth()).padStart(2, '0')
  const dd = String(newDate.getDate()).padStart(2, '0')
  const hour = newDate.getHours() <= 12 ? newDate.getHours() : newDate.getHours() - 12
  const minute = newDate.getMinutes()
  const seconds = newDate.getSeconds()
  const DN = newDate.getHours() < 12 ? 'AM' : 'PM'
  return locale === 'es'
    ? (dd + '/' + mm + '/' + yyyy + ' ' +
  hour + ':' + minute + ':' + seconds + ' ' + DN)
    : (mm + '/' + dd + '/' + yyyy + ' ' +
  hour + ':' + minute + ':' + seconds + ' ' + DN)
}

/**
 *
 * @param {Date} date
 * @param {String} locale 'es'/'en
 * @returns {String}
 *    es: 27/05/2021 1:5:10 AM
 *    en: 05/27/2021 1:5:10 PM
 */
export function getFormattedTimeZoneDateTime (date = new Date(), locale = 'en') {
  const dateReplace = date.replace(/\D+/g, '|')
  const dateSplit = dateReplace.split('|')

  const yyyy = dateSplit[0]
  const mm = dateSplit[1]
  const dd = dateSplit[2]
  const actualHour = dateSplit[3]
  const hour = actualHour <= 12 ? actualHour : actualHour - 12
  const minute = dateSplit[4]
  const seconds = dateSplit[5]
  const DN = actualHour < 12 ? 'AM' : 'PM'
  return locale === 'es'
    ? (dd + '/' + mm + '/' + yyyy + ' ' +
  hour + ':' + minute + ':' + seconds + ' ' + DN)
    : (mm + '/' + dd + '/' + yyyy + ' ' +
  hour + ':' + minute + ':' + seconds + ' ' + DN)
}

const isValidDate = d => d instanceof Date && !isNaN(d)

/**
 *
 * @param {Date} date
 * @returns {String} ex: 05/07/2021
 */
export function getDateString (date) {
  if (!date || !isValidDate(date)) return ''
  const yyyy = date.getFullYear()
  const mm = String(1 + date.getMonth()).padStart(2, '0')
  const dd = String(date.getDate()).padStart(2, '0')
  return mm + '/' + dd + '/' + yyyy
}

/**
 *
 * @param {Date} date
 * @param {Boolean} twelveTimeFormat
 * @returns {String}
 *  -- twelveTimeFormat = true --
 *    ex: '05:25 AM', '11:07 PM'
 *  -- twelveTimeFormat = false --
 *    ex: '05:35', '18:04'
 */
export function getFormattedTime (date, twelveTimeFormat = true) {
  if (!date || !isValidDate(date)) return ''
  let formattedTime
  if (twelveTimeFormat) {
    const hour = date.getHours() <= 12 ? date.getHours() : date.getHours() - 12
    const minute = date.getMinutes()
    const DN = date.getHours() < 12 ? 'AM' : 'PM'
    formattedTime = (hour < 10 ? '0' + hour : hour) + ':' + (minute < 10 ? '0' + minute : minute) + ' ' + DN
  } else {
    const hour = date.getHours()
    const minute = date.getMinutes()
    formattedTime = (hour < 10 ? '0' + hour : hour) + ':' + (minute < 10 ? '0' + minute : minute)
  }
  return formattedTime
}

/**
 *
 * @param {Date} date
 * @returns {String} ex: 2021-05-07
 */
export function getFormattedDateFromFullDateAndTime (date) {
  try {
    if (!date) return ''

    let dateValue = date
    if (!isValidDate(date)) {
      dateValue = new Date(date)
    }
    const year = dateValue.getFullYear()
    const month = String(dateValue.getMonth() + 1).padStart(2, '0')
    const day = String(dateValue.getDate()).padStart(2, '0')
    const formattedDate = year + '-' + month + '-' + day
    return formattedDate
  } catch (err) {
    console.log('getFormattedDateFromFullDateAndTime error', err)
    return null
  }
}

/**
 *
 * @param {String} dateString 2021-05-07
 * @param {String} timeString 05:24
 * @returns {Date}
 */
export function getDateFromDateAndTimeString (dateString, timeString) {
  const date = dateString.split('-')
  const time = timeString.split(':')
  const retDateAndTime = new Date(Date.UTC(date[0], date[1], date[2], time[0], time[1], 0))
  return retDateAndTime
}

/**
 *
 * @param {Date} date currentDate
 * @param {Number} days days to add
 * @returns {Date}
 */
export function addDaysToDate (date, days) {
  const yyyy = date.getFullYear()
  const m = date.getMonth()
  const day = date.getDate() + days
  return new Date(Date.UTC(yyyy, m, day, 0, 0, 0))
}

/**
 *
 * @param {Date} date currentDate
 * @returns {Number} weekNumber
 */
export function getWeekNumberOfDate (date) {
  const dateValue = date.getDate()
  return Math.ceil(dateValue / 7)
}

/**
 *
 * @param {dateString } date
 * @returns formattedDateString
 * convert 2022-03-17 => 2022/03/17
 */
export function changeDateStringFormat (date) {
  const formattedDate = date.replace('-', '/')
  return formattedDate
}

/**
 *
 * @param {Date} startDate startDate
 * @param {Date} endDate endDate
 * @returns different days
 */
export function getDaysDifference (startDate, endDate) {
  const diffTime = Math.abs(endDate - startDate)
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  return diffDays
}

export const deepCopy = (array) => {
  return cloneDeep(array)
}

export function numberWithCommas (x) {
  return (Math.abs(x)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function sortingFunc (array, obj) {
  array.sort((a, b) => {
    try {
      if (a[obj.key] == null || a[obj.key] == '') {
        return obj.asc ? 1 : -1
      }
      if (b[obj.key] == null || b[obj.key] == '') {
        return obj.asc ? -1 : 1
      }
      const sp = '&#160'
      const eleB = a[obj.key] ? String(a[obj.key]) : sp
      const eleA = b[obj.key] ? String(b[obj.key]) : sp
      if (obj.type && obj.type.name === 'Date') {
        const isValidDate = (str) => !isNaN(Date.parse(str))
        if (isValidDate(eleA) && isValidDate(eleB)) {
          const dateA = new Date(eleA)
          const dateB = new Date(eleB)
          return obj.asc ? dateA - dateB : dateB - dateA
        }
      } else {
        const numA = parseFloat(a[obj.key])
        const numB = parseFloat(b[obj.key])
        if (!isNaN(numA) && !isNaN(numB) && numA == a[obj.key] && numB == b[obj.key]) {
          return obj.asc ? numB - numA : numA - numB
        } else if (!isNaN(numA) && numA == a[obj.key]) {
          return obj.asc ? 1 : -1
        } else if (!isNaN(numB) && numB == b[obj.key]) {
          return obj.asc ? -1 : 1
        } else {
          const dotRegex = /^\./
          const underscoreRegex = /^_/
          const getPrefixPriority = (str) => {
            if (dotRegex.test(str)) return 1
            if (underscoreRegex.test(str)) return 2
            return 3
          }
          const priorityA = getPrefixPriority(eleA)
          const priorityB = getPrefixPriority(eleB)
          if (priorityA !== priorityB) {
            return obj.asc ? priorityA - priorityB : priorityB - priorityA
          }
          return obj.asc ? eleA.localeCompare(eleB, undefined,
            { numeric: true, sensitivity: 'base' })
            : eleB.localeCompare(eleA, undefined,
              { numeric: true, sensitivity: 'base' })
        }
      }
    } catch (error) {
      console.log(error)
    }
  })
}

const retryCallWrapper = function () {
  let count = 0
  const baseDelay = 500
  const retryCall = (AsyncJob, originalRequest) => {
    return new Promise((resolve) => {
      count++
      setTimeout(() => resolve(AsyncJob(originalRequest)), (Math.floor((count - 1) / 10) + 1) * baseDelay)
    })
  }
  return {
    retryCall
  }
}

export function asyncJob () {
  const AsyncJob = axios.create()
  const retryWrapper = retryCallWrapper()
  AsyncJob.interceptors.response.use(response => {
    const { config, data: { Body: { HasResult, PercentComplete, AsyncJobStateId, Status } } } = response
    store.commit('setAsyncPercent', PercentComplete)
    store.commit('setAsyncStateId', AsyncJobStateId)
    store.commit('setAsyncStatus', Status)
    store.commit('setAsyncHasResult', HasResult)
    const originalRequest = config
    if (HasResult || AsyncJobStateId === 2) {
      return response
    } else if (AsyncJobStateId === 3) {
      return AsyncJobStateId
    } else {
      return retryWrapper.retryCall(AsyncJob, originalRequest)
    }
  })
  return AsyncJob
}

export function getPendingReturns (items) {
  const filteredReturns = items.reduce((res, cur) => {
    const x = res.findIndex(item => item.ItemId == cur.ItemId)
    if (x == -1) {
      return res.concat([cur])
    } else {
      res[x].Quantity = +res[x].Quantity + +cur.Quantity
      return res
    }
  }, [])
  return filteredReturns
}

export function getCategories (items, isTool) {
  if (!items.length) {
    return []
  }
  const res = {}
  const categories = []
  items.forEach(item => {
    if (isTool ? item.ItemType !== 'M' : item.ItemType == 'M') {
      const { CategoryId: categoryId } = item
      if (!(categoryId in res)) res[categoryId] = []
      res[categoryId].push(item)
    }
  })
  Object.keys(res).forEach(key => {
    const obj = {}
    obj.CategoryName = res[key][0].Category
    obj.CategoryId = key
    obj.Items = res[key]
    obj.ItemCount = 0
    res[key].forEach(item => {
      obj.ItemCount += item.AvailableQuantity
    })
    categories.push(obj)
  })
  return categories
}

export function insertSpace (string) {
  let str = capitalizeFirstLetter(string)
  str = str.replace(/([a-z])([A-Z])/g, '$1 $2')
  str = str.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
  return str.trim()
}

export function getMonthEndDate (month, year) {
  return new Date(year, month, 0).getDate()
}

export function getExportFileName (name) {
  const newDate = new Date()
  const yyyy = newDate.getFullYear()
  const mm = String(1 + newDate.getMonth()).padStart(2, '0')
  const dd = String(newDate.getDate()).padStart(2, '0')
  const hour = String(newDate.getHours()).padStart(2, '0')
  const minute = String(newDate.getMinutes()).padStart(2, '0')
  const sec = String(newDate.getSeconds()).padStart(2, '0')
  return `${name}-${yyyy}-${mm}-${dd}-${hour}-${minute}-${sec}`
}

export function exportDataToXlsx (results, templates) {
  const reportData = XLSX.utils.json_to_sheet(Object.entries(results)[0][1])
  const fileName = getExportFileName(templates.Name)
  const key = templates.Name
  const obj = {}
  obj[key] = reportData
  const workBook = {
    SheetNames: [templates.Name],
    Sheets: obj
  }
  XLSX.writeFile(
    workBook,
    `${fileName}.xlsx`
  )
}

export function getFormattedTimeString (time) {
  const hours = String(time.getHours()).padStart(2, '0')
  const mins = String(time.getMinutes()).padStart(2, '0')
  return hours + ':' + mins
}

export async function getAsyncJob (refToDialog, param, callback) {
  if (refToDialog) {
    refToDialog.open()
  }
  const axiosSource = axios.CancelToken.source()
  const cancel = { cancel: axiosSource.cancel }
  callback(cancel)
  await asyncJob().post('/api/core/JobsService/GetAsyncJobRow', param, {
    cancelToken: axiosSource.token
  })

  setTimeout(() => {
    $('#dlgMessage').addClass('done')
    $('#dlgMessage').modal('hide')
  }, 0)

  if (refToDialog) {
    setTimeout(() => refToDialog.close(), 0)
  }
}

export function showLongRunningReportMessage () {
  $('#dlgMessage').removeClass('done')
  setTimeout(() => {
    if (!$('#dlgMessage').hasClass('done')) {
      $('#dlgMessage').modal('show')
    }
  }, 10000)
}

export async function getAsyncJobForCustomReport (isSaveOrRunReport, refToDialog, param, callback) {
  if (isSaveOrRunReport && refToDialog) {
    refToDialog.open()
  }
  const axiosSource = axios.CancelToken.source()
  const cancel = { cancel: axiosSource.cancel }
  callback(cancel)
  const result = await asyncJob().post('/api/core/JobsService/GetAsyncJobRow', param, {
    cancelToken: axiosSource.token
  })
  if (isSaveOrRunReport && refToDialog) {
    setTimeout(() => refToDialog.close(), 0)
  }
  return result
}

export async function sendFeedback (feature, toast) {
  const params = JSON.stringify({
    feature: '\'' + feature + '\'',
    sessionId: '\'' + localStorage.getItem('SessionId') + '\''
  })
  try {
    store.commit('setLoading', true)
    const { data } = await axios.post('/api/core/SurveysService/GetBetaFeedbackLink', params)
    window.open(data.Body, '_blank')
    store.commit('setLoading', false)
  } catch (error) {
    store.commit('setLoading', false)
    if (error.response) {
      errorHandler(toast, error.response.statusText)
    }
  }
}

export function saveServiceRequests ({
  serviceRequestId,
  organizationId,
  itemId,
  requestedById,
  inventoryKey,
  descriptionId,
  priorityLevelId,
  note,
  statusId,
  number,
  createdOn,
  createdById,
  isStandalone
}) {
  const params = {
    requests: JSON.stringify({
      ServiceRequestId: serviceRequestId,
      OrganizationId: organizationId,
      ItemId: itemId,
      RequestedById: requestedById,
      InventoryKey: inventoryKey,
      DescriptionId: descriptionId,
      PriorityLevelId: priorityLevelId,
      Note: note,
      StatusId: statusId,
      Number: number,
      CreatedOn: createdOn,
      CreatedById: createdById,
      ModifiedOn: getDateAndTimeString(),
      ModifiedById: localStorage.getItem('UserId'),
      IsStandalone: isStandalone,
      Deleted: false
    })
  }
  return axios.post('/api/core/WorkOrdersService/SaveServiceRequest', params)
}

export async function getDefaultQtyToOrder (modelId) {
  const params = {
    masterId: JSON.stringify(modelId)
  }
  const { data } = await axios.post('/api/core/StockPointDataService/GetQuantityToOrder', params)
  if (!data.Body) {
    data.Body = 1
  }
  return data.Body
}

export async function getModelDetail (masterId) {
  const params = {
    masterId: `"${masterId}"`
  }
  const { data } = await axios.post('/api/core/ModelsService/GetModel', params)
  return data.Body
}

export async function getPurchaseCost (modelId, shipToEntityId) {
  const params = {
    masterId: JSON.stringify(modelId),
    entityId: JSON.stringify(shipToEntityId)
  }
  const { data } = await axios.post('/api/core/PurchasingService/GetLastPurchaseCost', params)
  return data.Body
}

export async function getPurchaseCosts (modelIds, shipToEntityId) {
  const params = {
    masterIds: JSON.stringify(modelIds),
    entityId: JSON.stringify(shipToEntityId)
  }
  const { data } = await axios.post('/api/core/PurchasingService/GetLastPurchaseCostList', params)
  return data.Body
}

// browser service param
export function getBrowserServiceParam (obj) {
  const initSearchParam = {
    AssignedToId: null,
    OwnerId: null,
    AssignedToIds: null,
    NotAssignedToId: null,
    CategoryId: null,
    DepartmentId: null,
    IncludeKitContents: true,
    ItemClassId: null,
    ItemNumberOrBarcode: null,
    MasterId: null,
    MasterIds: [],
    DescriptionId: null,
    SuppressUnstocked: true,
    ItemIds: [],
    KitId: null,
    FilterBasedOnCurrentStockpoint: true,
    CurrentStockpoint: null,
    Asset: null,
    AssetHashes: [],
    AssetIds: [],
    IncludeLostFound: true,
    IncludeDeleted: false,
    ItemTypeId: 0,
    CertificationId: null,
    SupervisorId: null,
    StatusId: null,
    ManufacturerId: null,
    SerialNumber: null,
    DescriptionContains: null,
    KitNumber: null,
    PastReturnDateOnly: false,
    ResponsiblePartyId: null,
    AssignedToEntityType: null,
    IsBleTaggedOnly: false,
    AssetTypes: 15,
    FuzzySearchText: null,
    SubTypeKey: '\0',
    Vin: null,
    LicensePlateNumber: null,
    LicensePlateState: null
  }
  return { ...initSearchParam, ...obj }
}

export async function getBrowserItems (criteria, isExtended, toast) {
  const params = {
    orgId: `"${localStorage.getItem('OrgId')}"`
  }
  try {
    if (isExtended) {
      params.criteria = criteria
      const { data } = await axios.post('/api/core/ExtendedBrowserService/GetExtended', params)
      return data.Body
    } else {
      params.c = criteria
      const { data } = await axios.post('/api/core/BrowserService/Get', params)
      return data.Body
    }
  } catch (error) {
    if (error.response) {
      errorHandler(toast, error.response.statusText)
    }
    return []
  }
}

export async function getModels (selectedCategoryId, kind, toast) {
  const params = JSON.stringify({
    orgId: `"${localStorage.getItem('OrgId')}"`,
    masterId: 'null',
    number: 'null',
    manufacturer: 'null',
    manufacturerId: 'null',
    categoryId: JSON.stringify(selectedCategoryId),
    kind: kind,
    excludeDeleted: 'true',
    modelIds: 'null',
    description: 'null',
    categoryDescription: 'null',
    itemTypes: 'null',
    model: 'null',
    modelYear: 'null',
    upc: 'null',
    dataSourceId: 'null',
    page: 'null',
    pageSize: 'null'
  })
  try {
    const { data } = await axios.post('/api/core/ModelsService/FindAll', params)
    return data.Body
  } catch (error) {
    if (error.response) {
      errorHandler(toast, error.response.statusText)
    }
    return []
  }
}

export async function getRepairParts (masterId, toast) {
  const params = {
    orgId: `"${localStorage.getItem('OrgId')}"`,
    typeId: 1,
    masterId: `"${masterId}"`
  }
  try {
    const { data } = await axios.post('/api/core/ModelsService/GetMasterRelationshipViews', params)
    return data.Body.map(model => {
      model.Id = model.RelatedMasterId
      return model
    })
  } catch (error) {
    if (error.response) {
      errorHandler(toast, error.response.statusText)
    }
    return []
  }
}

// custom validators
export const phone = helpers.regex('phone', /^(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?$/)
export const fax = helpers.regex('fax', /^((\+?\d{1,}(\s?|-?)\d*(\s?|-?))?\(?\d{2,}\)?(\s?|-?)\d{3,}\s?(\s?|-?)\d{3,})$/)
export const url = helpers.regex('url', /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/)
export const zip = helpers.regex('zip', /^((\d{5}[-\s]\d{4})|(\d{4}[-\s]\d{4})|(\d{4})|(\d{5})|([A-Za-z]\d[A-Za-z]\s\d[A-Za-z]\d))$/)
export const urlWithOptionalProtocol = helpers.regex('url', /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/)

export function getTransferDetailObj (list, transferHeader, breakFromKit = false, isCheckIn) {
  const obj = {}
  const FromKey = {}
  const flag = list.isInventoryAdjustmentBrowser
  if (!transferHeader.FromId) {
    transferHeader.FromId = flag ? list.AssignmentToId : list.assignedToId
  }
  FromKey.EntityId = flag ? list.AssignedToId : list.assignedToId
  FromKey.EntityId = FromKey.EntityId ?? transferHeader.FromId
  FromKey.InventoryKeyId = null
  FromKey.Hash = null
  FromKey.PersonalIssue = flag ? list.PersonalIssue : list.personalIssue
  FromKey.ItemId = list.itemId ?? list.ItemId
  FromKey.OwnerId = flag ? list.OwnerId : list.ownerId
  FromKey.KitId = flag ? list.KitId : list.kitId
  FromKey.KitNumber = flag ? list.KitNumber : list.kitNumber
  FromKey.StatusCodeId = flag ? list.ItemStatusId : list.itemStatusId
  FromKey.isItemBrowser = !!list.isItemBrowser || !!isCheckIn
  FromKey.isInventoryAdjustmentBrowser = !!flag || !!isCheckIn
  obj.FromEntity = flag ? list.Assignment : list.assignedTo
  obj.FromOwner = flag ? list.Owner : list.owner
  obj.FromCenterId = list.FromCenterId
  obj.ItemStatus = flag ? list.Status : list.itemStatus
  obj.Description = flag ? list.ItemDescription : list.description
  obj.BrekFromKit = breakFromKit
  obj.ToStatusCodeId = flag ? list.ItemStatusId : list.itemStatusId
  obj.ToPersonalIssue = flag ? list.PersonalIssue : list.personalIssue
  obj.AllAssociated = false
  obj.PickTicketPickedId = list.pickTicketPickedId !== null && list.pickTicketPickedId !== undefined
    ? list.pickTicketPickedId
    : null
  obj.Charge = 0.0
  obj.Amount = null
  obj.LostAndFound = !!list.lostFoundId
  obj.TwEntityCredit = list.manager
  obj.ItemNumber = flag ? list.ItemNumber : list.itemNumber
  obj.isCheckIn = isCheckIn == true && !(list.PersonalIssue || list.personalIssue)
  obj.Quantity = (list.kitId || list.isItemBrowser || flag || obj.isCheckIn == true)
    ? Math.max((flag ? list.Quantity : list.quantity), 1)
    : (isCheckIn == true ? 0 : 1)
  obj.previousQuantity = list.previousQuantity
  obj.totalQty = flag ? list.Quantity : list.quantity
  obj.QtyOut = flag ? list.Quantity : list.quantity
  obj.Meter = flag ? list.MeterLevel : list.meterLevel
  obj.ItemType = list.itemType
  obj.ToCenterId = null
  obj.ToCodeId = null
  obj.Id = uuid.v4()
  obj.FromKey = FromKey
  obj.ResponsiblePartyId = null
  obj.OrgId = list.organizationId
  obj.TransferId = transferHeader.Id
  obj.SerialNumber = flag ? list.SerialNumber : list.serialNumber
  obj.CountTicket = flag ? list.TicketNumber : list.countTicket
  obj.ItemSubType = list.itemSubType
  return obj
}

export function setTransferHeader (header) {
  const obj = {}
  obj.Number = ''
  obj.AssignedById = localStorage.getItem('UserId')
  obj.DeliveredById = null
  obj.DocumentNumber = null
  obj.FromCenterId = null
  obj.FromCodeId = null
  obj.FromId = null
  obj.Notes = null
  obj.OrgId = localStorage.getItem('OrgId')
  obj.ResponsiblePartyId = null
  obj.ToCenterId = null
  obj.ToCodeId = null
  obj.ToId = null
  obj.TransferSourceId = 0
  obj.Source = 0
  obj.StockpointId = null
  obj.Deleted = false
  obj.Inactive = false
  obj.ReturnDate = null
  obj.AssignmentDate = getFormatedDateString(new Date()) + 'T' + getFormattedTimeString(new Date())
  obj.CreatedOn = getFormatedDateString(new Date()) + 'T' + getFormattedTimeString(new Date())
  obj.isAssignmentDateChanged = false
  return { ...obj, ...header }
}

export function setPickHeader (pickTicketNumber) {
  const obj = {}
  obj.Number = pickTicketNumber
  obj.OrgId = localStorage.getItem('OrgId')
  obj.FromId = null
  obj.FromCenterId = null
  obj.FromCodeId = null
  obj.ToId = null
  obj.ToCenterId = null
  obj.ToCodeId = null
  obj.Note = null
  obj.RequestedById = null
  obj.AssignedToId = null
  obj.StatusId = PICK_STATUS.DRAFT
  obj.DueDate = getFormatedDateString(new Date()) + 'T' + getFormattedTimeString(new Date())
  obj.CreatedOn = getFormatedDateString(new Date()) + 'T' + getFormattedTimeString(new Date())
  obj.ModifiedOn = getFormatedDateString(new Date()) + 'T' + getFormattedTimeString(new Date())
  obj.TemplateId = null
  obj.OriginationId = null
  obj.OriginationType = ''
  return obj
}

export function setRequisitionRequestHeader (requisitionNumber) {
  const obj = {}
  obj.Number = requisitionNumber
  obj.OrgId = localStorage.getItem('OrgId')
  obj.RequestedFromId = null
  obj.RequestedFromCostCenterId = null
  obj.RequestedFromCostCodeId = null
  obj.RequestedToId = null
  obj.RequestedToCostCenterId = null
  obj.RequestedToCostCodeId = null
  obj.Note = null
  obj.RequestedById = null
  obj.AssignedToId = null
  obj.Status = PICK_STATUS.DRAFT
  obj.RequestedDueDate = getFormatedDateString(new Date()) + 'T' + getFormattedTimeString(new Date())
  obj.RequestedCreatedOn = getFormatedDateString(new Date()) + 'T' + getFormattedTimeString(new Date())
  obj.RequestedModifiedOn = getFormatedDateString(new Date()) + 'T' + getFormattedTimeString(new Date())
  obj.TemplateId = null
  obj.OriginationId = null
  obj.OriginationType = ''
  return obj
}

export function setModelUpdateParam (obj) {
  const {
    masterId,
    number,
    orgId,
    itemType,
    descriptionId,
    categoryId,
    createdById,
    createdBy,
    createdOn,
    modifiedBy,
    modifiedById,
    itemTypeId
  } = obj
  return {
    MasterId: masterId,
    MasterNumber: number,
    OrganizationId: orgId,
    ItemType: itemType,
    DescriptionId: descriptionId,
    CategoryId: categoryId,
    ManufacturerId: null,
    ManufacturerDescription: null,
    ManufacturerPartNumber: null,
    Model: null,
    Upc: null,
    Specifications: null,
    Cost: null,
    CostDate: null,
    Msrp: null,
    MsrpDate: null,
    DefaultClassId: null,
    DefaultCertificationId: null,
    UomId: null,
    CreatedById: createdById,
    CreatedBy: createdBy,
    CreatedOn: createdOn ?? getDateAndTimeString(),
    ModifiedById: modifiedById,
    ModifiedBy: modifiedBy,
    ModifiedOn: getDateAndTimeString(),
    DataSourceId: null,
    VendorId: null,
    Deleted: false,
    Inactive: false,
    IsDoNotBill: false,
    ReplacementCost: null,
    ModelYear: null,
    ItemTypeId: itemTypeId
  }
}

export function getMatchedRoute (name) {
  return recurssiveSearch(routeJson, name)
}

function recurssiveSearch (object, value) {
  if (Array.isArray(object)) {
    for (const obj of object) {
      const result = recurssiveSearch(obj, value)
      if (result) {
        return result
      }
    }
  } else {
    if (object.name == value || object.route_name == value) {
      return object
    }

    for (const k of Object.keys(object)) {
      if (typeof object[k] === 'object') {
        const nestedObj = recurssiveSearch(object[k], value)
        if (nestedObj !== null && typeof nestedObj !== 'undefined') {
          return nestedObj
        }
      }
    }

    return null
  }
}

export function isValidRoute (route, childroute, isEHSLink = false) {
  return this.routerNoGuard(route, childroute) && this.routerEntitlement(route, childroute) && this.showNavLink(route, childroute, isEHSLink)
}

export function routerNoGuard (route, childroute) {
  if (!hasPermission(route, childroute)) {
    return false
  }

  if (!route.completed && !store.getters.showCloudWorkInProgress) {
    return false
  }

  if (childroute && !childroute.completed && !store.getters.showCloudWorkInProgress) {
    return false
  }

  if (!route.jobSiteUser && !store.getters.showPlatformNavigation) {
    if (!(route.wareHouseUser || route.backOfficeUser)) {
      return false
    }
  }

  return true
}

function hasPermission (route, childroute) {
  if (childroute) {
    return (!route.guard || allPermitted(route.guard)) &&
    (!childroute.guard || allPermitted(childroute.guard))
  }
  return (!route.guard || allPermitted(route.guard))
}

function allPermitted (permissions) {
  if (!permissions) return true
  if (!permissions.length) return true
  return !permissions.some(permission => !store.getters.userPermission.includes(permission))
}

export function somePermitted (permissions) {
  if (!permissions) return true
  if (!permissions.length) return true
  return parentArrayHasSomeChildren(store.getters.userPermission, permissions)
}

function allEntitled (entitlement) {
  if (!entitlement) return true
  return store.getters.userEntitlement.includes(entitlement)
}

export function routerEntitlement (route, childroute) {
  if (childroute) {
    return (!route.entitlement || allEntitled(route.entitlement)) &&
    (!childroute.entitlement || allEntitled(childroute.entitlement))
  }
  return !route.entitlement || allEntitled(route.entitlement)
}

export function hasEntitledPermission (entitlement, permissions) {
  return allEntitled(entitlement) && allPermitted(permissions)
}

// used for testing if any elements in a child array
// exist in the given parent array
// true if parent has any of the children
export function parentArrayHasSomeChildren (parentArray, childArray) {
  var childExistsInParent = false
  if (!childArray || childArray.length === 0) {
    return true
  }
  for (let i = 0; i < childArray.length; i++) {
    if (parentArray.includes(childArray[i])) {
      childExistsInParent = true
      break
    }
  }

  return childExistsInParent
}

function isWareHouseUser () {
  return store.getters.jobSiteUser === 1 || store.getters.showPlatformNavigation
}

function isBackOfficeUser () {
  return store.getters.jobSiteUser === 2 || store.getters.showPlatformNavigation
}

const isEHSOnly = () => {
  return store.getters.userEntitlement.includes('SafetyTierMaxAdmins') &&
  !store.getters.userEntitlement.includes('T_And_E')
}

export function showNavLink (route, childroute, isEHSLink) {
  if (store.getters.showCloudWorkInProgress) return true
  // Intercom is in both route files - even though it's an ehs route we should respect its flag
  if (route.matchPath.includes('getSupport')) return (store.getters.isShowIntercom && !store.getters.isSupportRedesign)
  // Hide all nav bar items that aren't directly related
  // to EHS user management

  else if (isEHSOnly()) {
    return isEHSLink || IsEHSOnlyRoute(route, childroute)
  } else if (childroute && (childroute.path == '/returns' || childroute.path == '/returns/history')) return true
  else if (route.matchPath.includes('Documentation')) return !store.getters.isSupportRedesign
  else if (childroute && childroute.path === '/reservations/scheduling-browser') return store.getters.isShowScheduling
  else if (childroute && childroute.path === '/reservations/scheduling-request') return store.getters.isShowScheduling
  else if (route.matchPath.includes('reservations') && route.backOfficeUser && isBackOfficeUser() == route.backOfficeUser) return store.getters.showCloudScheduleBackOffice
  else if (route.matchPath.includes('reservations') && route.wareHouseUser && isWareHouseUser() == route.wareHouseUser) return store.getters.showCloudScheduleWarehouse
  else if (route.matchPath.includes('reservations')) return store.getters.showCloudReservations
  else if (childroute && childroute.path === '/scheduling/scheduling-request') return store.getters.isShowScheduling
  else if (childroute && childroute.path === '/scheduling/scheduling-calendar') return store.getters.isShowScheduling
  else if (childroute && childroute.path === '/scheduling/scheduling-browser') return store.getters.isShowScheduling
  else if (childroute && childroute.path === '/reservations') return store.getters.showCloudReservations
  else if (childroute && childroute.path === '/reservations/history/items') return store.getters.showCloudReservations
  else if (route.id && route.id === 'EquipmentManagement') return store.getters.isShowEquipmentNav
  else if (route.matchPath.includes('scheduling')) return store.getters.isShowScheduling
  else if (route.matchPath.includes('schedulingPlatformNav')) return store.getters.showCloudReservations || store.getters.isShowScheduling
  else if (route.matchPath.includes('tasks')) {
    if (store.getters.isShowEnhancedWorkflows) return false
    else return store.getters.isShowTasks
  } else if (childroute && childroute.name === 'Tasks') return store.getters.isShowTasks
  else if (childroute && childroute.path === '/administration/lists/tasks-categories') return store.getters.isShowTasks
  else if (childroute && childroute.path === '/administration/lists/tasks-priorities') return store.getters.isShowTasks
  else if (childroute && childroute.path === '/administration/lists/tasks-statuses') return store.getters.isShowTasks
  else if (childroute && childroute.path === '/administration/document-central') return store.getters.isShowDocumentCentral
  else if (childroute && childroute.path === '/administration/safety-plan') return store.getters.isShowSafetyPlan
  else if (route.matchPath.includes('location-map')) return store.getters.isViewGoogleMap
  else if (route.matchPath.includes('safety')) return store.getters.isShowSafetyMenu
  else if (route.matchPath.includes('insights')) return store.getters.isShowInsights
  else if (childroute && childroute.path === '/insights') return store.getters.isShowInsights
  else if (childroute && childroute.path === '/fleet/fuel-transaction-browser') return store.getters.isShowFuelCards
  else if (childroute && childroute?.name === 'Fuel') return store.getters.isShowFuelCards
  else if (childroute && childroute.path === '/administration/lists/fuel-cards') return store.getters.isShowFuelCards
  else if (childroute && childroute.path === '/administration/lists/fuel-employees') return store.getters.isShowFuelCards
  else if (childroute && childroute.path == '/reports/dashboards') return store.getters.isShowReportCustomDashboard
  else if (route.matchPath.includes('workmanagement') && !childroute) return (store.getters.isShowEnhancedWorkflows || store.getters.isShowTasks || store.getters.isShowWorkflowApprovals)
  else if (childroute && childroute.path === '/workflow/task-browser') return store.getters.isShowTasks
  else if (childroute && childroute.path === '/workflow/workflow-approvals') return store.getters.isShowWorkflowApprovals
  else if (childroute && childroute.path == 'requisition-browser') return store.getters.orgSettings.EnableRequisitionBrowserWorkflow
  else if (childroute && childroute.path == 'requisition-browser/view') return store.getters.orgSettings.EnableRequisitionBrowserWorkflow
  else if (childroute && childroute.path == 'requisition-browser/create') return store.getters.orgSettings.EnableRequisitionBrowserWorkflow
  else if (childroute && childroute.path == 'job-stock') return store.getters.isShowJobStock
  else if (route.wareHouseUser && isWareHouseUser() == route.wareHouseUser) return true
  else if (route.backOfficeUser && isBackOfficeUser() == route.backOfficeUser) return true
  else if (childroute ? route.released && (childroute.completed || childroute.coming) : route.released) return true
  return false
}

function IsEHSOnlyRoute (route, childroute) {
  if (route.matchPath.includes('safety')) {
    return store.getters.isShowSafetyMenu
  } else if (route.matchPath.includes('administration')) {
    if (childroute === undefined || childroute === null) {
      return true
    }

    if (childroute.path && childroute.path == '/ehs/administration/users') {
      return store.getters.isShowUsersInAdmin
    }

    if (childroute.path && childroute.path == '/administration/document-central') {
      return store.getters.isShowDocumentCentral
    }

    if (childroute.matchPath && childroute.matchPath.includes('administration/lists')) {
      return true
    }

    if (childroute.path && ['/administration/change-password', '/administration/lists/employees'].includes(childroute.path)) {
      return true
    }
  } else if (route.matchPath.includes('help')) return true
  else if (route.name.includes('Reports and Graphs')) {
    if (childroute && childroute.path && (
      childroute.path == '/reports' ||
      childroute.path == '/reports/scheduled' ||
      childroute.path == '/reports/dashboards' ||
      childroute.path == '/insights'
    )) return false
    return (store.getters.isEHSCustomReports &&
      store.getters.userEntitlement.includes('SafetyTierCustomReports'))
  } else return false
}

export const getInitPOHeader = () => ({
  Number: null,
  OrgId: localStorage.getItem('OrgId'),
  StatusId: 3,
  PurchaseOrderId: uuid.v4(),
  VendorId: null,
  VendorNotes: null,
  DestinationEntityId: null,
  DestinationEntityNotes: null,
  CostCenterId: null,
  CostCodeId: null,
  ToolWatchBilled: false,
  OrderedById: localStorage.getItem('UserId'),
  Requisition: null,
  ShippingMethodId: null,
  OrderDate: getDateAndTimeString(),
  ExpectedDeliveryDate: getDateAndTimeString(),
  DocumentNumber: null,
  Notes: null,
  PrimaryReceivingEntityId: null,
  ShippingCharges: null,
  TaxCharges: null,
  ShippingCostCodeId: null,
  TaxCostCodeId: null,
  CreatedById: null,
  CreatedBy: null,
  CreatedOn: null,
  ModifiedById: null,
  ModifiedBy: null,
  ModifiedOn: null,
  Deleted: false,
  SourceId: 0,
  StockpointId: null
})

// check if all defined entities of two object arrays are the same
export const isSameEntity = (a, b) => (props) => {
  let res = true
  props.forEach((prop) => {
    res = res && JSON.stringify(a[prop]) === JSON.stringify(b[prop])
  })
  return res
}

export function formatDate (date) {
  var d = new Date(date)
  var month = '' + (d.getMonth() + 1)
  var day = '' + d.getDate()
  var year = d.getFullYear()
  if (month.length < 2) {
    month = '0' + month
  }
  if (day.length < 2) {
    day = '0' + day
  }
  return [year, month, day].join('-')
}

// get the differneces from only the left array
export const onlyInLeft = (left, right, props, compareFunc) =>
  left.filter(leftVal =>
    !right.some(rightVal =>
      compareFunc(leftVal, rightVal)(props)))

export const resetWorkOrder = () => {
  store.commit('setHasBeenChangeForRequiredTools', false)
  store.commit('setHasBeenChangeForParts', false)
  store.commit('setHasBeenChangeForAssignment', false)
  store.commit('setHasBeenChangeForCharges', false)
  store.commit('setHasBeenChangeForTasks', false)
  store.commit('resetWorkOrderTasks')
  store.commit('resetWorkOrdersTasksPerformed')
  store.commit('resetRequiredTools')
  store.commit('resetRequiredParts')
  store.commit('resetRequiredPartsUsed')
  store.commit('resetWorkOrderTransfers')
  store.commit('resetWorkOrderCharges')
  store.commit('resetWorkOrderView')
}

export function isLeapYear (year) {
  return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0)
}

export function checkedAll (array) {
  if (!array.length) return false
  return !array.some(ele => !ele.checked)
}

export function checkedAllWithKey (array, key) {
  if (!array.length) return false
  return !array.some(ele => !ele[key])
}

export const getInventoryKeyId = async ({
  assignedToId,
  itemId,
  personalIssue,
  itemStatusId,
  kitId,
  ownerId
}) => {
  const params = {
    orgId: `"${localStorage.getItem('OrgId')}"`,
    key: JSON.stringify({
      InventoryKeyId: null,
      EntityId: assignedToId,
      ItemId: itemId,
      PersonalIssue: personalIssue,
      StatusCodeId: itemStatusId,
      KitId: kitId,
      OwnerId: ownerId,
      Hash: null
    })
  }
  const { data: { Body: inventoryKeyId } } = await axios.post('/api/core/InvKeysService/getKeyIdByKey', params)
  return inventoryKeyId
}

export async function getQuantitiesForAssets (Assets) {
  const quantityParams = JSON.stringify(Assets)
  const { data: { Body: AssetQuantities } } = await axios.post('/api/core/StockService/GetQuantitiesForAssets', quantityParams)
  return AssetQuantities
}

export const getQuantityByAsset = async ({
  assignedToId,
  itemId,
  personalIssue,
  itemStatusId,
  kitId,
  ownerId
}) => {
  const params = {
    orgId: `"${localStorage.getItem('OrgId')}"`,
    key: JSON.stringify({
      InventoryKeyId: null,
      EntityId: assignedToId,
      ItemId: itemId,
      PersonalIssue: personalIssue,
      StatusCodeId: itemStatusId,
      KitId: kitId,
      OwnerId: ownerId,
      Hash: null
    })
  }
  const { data: { Body: assetKey } } = await axios.post('/api/core/AssetsService/CreateKey', params)
  const quantityParams = {
    orgId: `"${localStorage.getItem('OrgId')}"`,
    a: JSON.stringify(assetKey)
  }
  const { data: { Body: quantity } } = await axios.post('/api/core/StockService/GetQuantityByAsset', quantityParams)
  return quantity
}

export function getRateSourceLabel (item) {
  if (item.Rate !== null && item.Rate !== undefined && !item.DoNotBill) {
    if (item.ItemType === 'M') {
      return 'buttons.each'
    }

    // T and Q tools with an assignment  charge
    if (item.RateSource && item.RateSource === 'initialCharge') {
      return 'buttons.perAssignment'
    } else if (item.RateSource && item.RateSource === 'hourly') {
      return 'buttons.perHour'
    }

    return 'buttons.perMonth'
  }

  if (item.ItemType === 'M') {
    return 'buttons.noRateSpecified'
  }

  return 'buttons.noMonthlyRateSpecified'
}

export function mergeObjects (target, source) {
  Object.keys(source).forEach(key => {
    if (typeof source[key] === 'object') {
      target[key] = mergeObjects(target[key] || {}, source[key])
    } else {
      target[key] = source[key]
    }
  })

  return target
}

export function assetKindType (assetKind) {
  switch (assetKind) {
    case 'A':
      return 0
    case 'T':
      return 1
    case 'M':
      return 2
    default:
      return 1
  }
}
export function getFormattedDateByLocale (date = new Date(), locale = 'en') {
  const yyyy = date.getFullYear()
  const mm = String(1 + date.getMonth()).padStart(2, '0')
  const dd = String(date.getDate()).padStart(2, '0')
  return locale === 'en'
    ? mm + '/' + dd + '/' + yyyy
    : dd + '/' + mm + '/' + yyyy
}
export function applyFiltersOnGrid (tableData, mergedHeader, tableFilter, locale = 'en') {
  mergedHeader?.forEach(header => {
    if (header.type == Date) {
      if (typeof (tableFilter[header.key]) != 'undefined' &&
        tableFilter[header.key] != '') {
        if (typeof (header.filtertype) != 'undefined' && header.filtertype == 'DateLocalString') {
          tableData = tableData.filter(row => {
            if (typeof (row[header.key]) != 'undefined' && row[header.key] != null) {
              return ((i18n.d(new Date(Date.parse(row[header.key])), 'short')).toUpperCase())
                .indexOf(tableFilter[header.key].toUpperCase()) > -1
            } else {
              return false
            }
          })
        } else if (typeof (header.filtertype) != 'undefined' && header.filtertype == 'DateTime') {
          tableData = tableData.filter(row => {
            if (typeof (row[header.key]) != 'undefined' && row[header.key] != null) {
              return getFormattedDate(new Date(row[header.key]), locale)
                .indexOf((tableFilter[header.key]).toString().toUpperCase()) > -1
            } else {
              return false
            }
          })
        } else {
          tableData = tableData.filter(row => {
            if (typeof (row[header.key]) != 'undefined' && row[header.key] != null) {
              return getFormattedDateByLocale(new Date(row[header.key]), locale)
                .indexOf((tableFilter[header.key]).toString().toLowerCase()) > -1
            } else {
              return false
            }
          })
        }
      }
    } else if (header.filterOptions && header.filterBy) {
      if (typeof (tableFilter[header.key]) != 'undefined' && tableFilter[header.key] != '') {
        tableData = tableData.filter(row => {
          return i18n.t(header.filterOptions).findIndex(
            item => item.Key.toString().toLowerCase().indexOf(tableFilter[header.key].toString().toLowerCase()) > -1 &&
            item[header.filterBy] === row[header.key]
          ) > -1
        })
      }
    } else if (header.hasDropdownFilter) {
      if (typeof (tableFilter[header.keyDropdownFilter]) != 'undefined' && tableFilter[header.keyDropdownFilter] != '') {
        tableData = tableData.filter(row => {
          return (row[header.keyDropdownFilter]).toString().toLowerCase()
            .indexOf((tableFilter[header.keyDropdownFilter]).toString().toLowerCase()) > -1
        })
      }
    } else {
      if (typeof (tableFilter[header.key]) != 'undefined' &&
        tableFilter[header.key] != '') {
        tableData = tableData.filter(row => {
          if (typeof (row[header.key]) != 'undefined' && row[header.key] != null) {
            if (row.itemSubType === 'E' && (tableFilter[header.key]).toString().toUpperCase() === 'E') {
              return (row.itemSubType).toString().toLowerCase()
                .indexOf((tableFilter[header.key]).toString().toLowerCase()) > -1
            } else {
              return (row[header.key]).toString().toLowerCase()
                .indexOf((tableFilter[header.key]).toString().toLowerCase()) > -1
            }
          } else {
            if (('Requisition' in row && 'Items' in row) && typeof (row.Requisition[header.key]) != 'undefined' && row.Requisition[header.key] != null) {
              return (row.Requisition[header.key]).toString().toLowerCase()
                .indexOf((tableFilter[header.key]).toString().toLowerCase()) > -1
            } else {
              if (typeof (row[header.key]) != 'undefined' && row[header.key] != null) {
                return (row[header.key]).toString().toLowerCase()
                  .indexOf((tableFilter[header.key]).toString().toLowerCase()) > -1
              } else {
                return false
              }
            }
          }
        })
      }
    }
  })
  return tableData
}
export function isValidRefreshAfterGivenMinute (currentDate, previousDate, minuteCount) {
  let isValidRefresh = false
  if (previousDate !== null && previousDate !== '') {
    const currentDateDatePart = new Date(currentDate).getDate()
    const previousDateDatePart = new Date(previousDate).getDate()
    if (currentDateDatePart > previousDateDatePart) {
      isValidRefresh = true
    } else {
      const currentDateTimePart = new Date(currentDate).getTime()
      const previousDateTimePart = new Date(previousDate).getTime()
      if (((currentDateTimePart / 60000) - (previousDateTimePart / 60000)) >= minuteCount) {
        isValidRefresh = true
      }
    }
  } else {
    isValidRefresh = true
  }
  // hot fix to use dashboards
  // needed to be one date per chart not one date
  return true
}
export function getFormattedDateWithTwoDigitYear (date = new Date()) {
  const newDate = new Date(date)
  const yy = newDate.getFullYear().toString().substring(2)
  const mm = String(1 + newDate.getMonth()).padStart(2, '0')
  const dd = String(newDate.getDate()).padStart(2, '0')
  const hour = newDate.getHours() <= 12 ? newDate.getHours() : newDate.getHours() - 12
  const minute = newDate.getMinutes()
  const second = newDate.getSeconds()
  const DN = newDate.getHours() < 12 ? 'AM' : 'PM'
  return (mm + '/' + dd + '/' + yy + ' ' +
  (hour < 10 ? '0' + hour : hour) + ':' +
  (minute < 10 ? '0' + minute : minute) + ':' +
  (second < 10 ? '0' + second : second) + ' ' + DN)
}

export function getHeadersAndDataKeys (isUserDefined, csvHeaderPath, gridHeaderPath, pageName, localUserStorage) {
  const headers = []
  const dataKeys = []
  let objDesktop = null
  if (isUserDefined) {
    const gridHeaderName = gridHeaderPath.split('.')[0]
    const headerName = pageName.concat('_', gridHeaderName)
    let displayColumns = localUserStorage.getData(headerName)
    if (displayColumns && displayColumns.length > 0) {
      displayColumns = JSON.parse(displayColumns)
      objDesktop = displayColumns.find(x => x.page === pageName && x.userid === localStorage.getItem('UserId') &&
        x.gridHeadr === gridHeaderName && x.orgId === localStorage.getItem('OrgId'))
      if (objDesktop) {
        objDesktop.columns = JSON.parse(objDesktop.columns)
        objDesktop.columns = applyAdditionalColumnFilters(objDesktop.columns)
        objDesktop.columns = objDesktop.columns?.map(x => {
          const result = i18n.t(gridHeaderPath).desktop?.find(y => y.key === x.key)
          return result
        })
        objDesktop.columns.forEach(ele => {
          headers.push(ele.label)
          dataKeys.push(ele.key)
        })
      }
    }
  }

  if (!isUserDefined || !objDesktop || !objDesktop.columns) {
    let header = mergeObjects(i18n.t(csvHeaderPath, 'en', {}), i18n.t(csvHeaderPath))
    header = applyAdditionalColumnFilters(header)
    header.forEach(ele => {
      headers.push(ele.label)
      dataKeys.push(ele.key)
    })
  }

  return { headers, dataKeys }
}

export function applyAdditionalColumnFilters (columns) {
  columns = columns.filter(x => store.getters.hasPredictLossModel || x.key !== 'needsVerificationScore')
  const flagColumns = columns.filter(x => Object.keys(x).includes('flag'))
  if (flagColumns && flagColumns.length > 0) {
    flagColumns?.forEach((data) => {
      const indexOf = columns?.map(y => y.key).indexOf(data.key)
      if (!store.getters[data.flag]) {
        if (indexOf >= 0) {
          columns.splice(indexOf, 1)
        }
      }
    })
  }
  return columns
}

export function getCsvData (isUserDefined, csvHeaderPath, gridHeaderPath, pageName, data, localUserStorage) {
  const { headers, dataKeys } = getHeadersAndDataKeys(isUserDefined, csvHeaderPath, gridHeaderPath, pageName, localUserStorage)
  const csvData = []
  for (const i in data) {
    const obj = {}
    for (const j in dataKeys) {
      obj[dataKeys[j]] = data[i][dataKeys[j]] ?? ''
    }
    csvData.push(obj)
  }
  return { csvData, headers }
}

export function checkIsToolEquipment (list) {
  if (list.itemSubType) {
    return list.itemSubType === 'E'
  }
  return false
}

export function getLoginPath () {
  let loginPath = '/login'
  const isPlatformLogin = localStorage.getItem('isPlatformLogin') ?? 'false'
  if (isPlatformLogin === 'true') {
    loginPath = '/login'
  }
  return loginPath
}

export function numberControl ($event) {
  var enteredvalue = ''
  enteredvalue = $event.target.value.replace(/[^0-9.]/g, '')
  const decimalIndex = enteredvalue.indexOf('.')
  if (decimalIndex !== -1) {
    const decimalPart = enteredvalue.substring(decimalIndex + 1)
    if (decimalPart.length > 2) {
      enteredvalue = enteredvalue.slice(0, decimalIndex + 3)
      return enteredvalue
    }
  }
  return enteredvalue
}

export function handleFocus (fieldValue) {
  if (fieldValue === '0.00') {
    fieldValue = ''
    return fieldValue
  }
  return fieldValue
}

export function handleBlur (fieldValue) {
  if (fieldValue === '') {
    fieldValue = '0.00'
    return fieldValue
  } else if (fieldValue[0] === '.') {
    fieldValue = '0' + fieldValue
    return fieldValue
  } else if (fieldValue.endsWith('.')) {
    fieldValue += '00'
    return fieldValue
  }
  return fieldValue
}

export function getPlatformLoginUrl () {
  if (process.env.VUE_APP_LOGIN_SERVICE_URL === null || process.env.VUE_APP_LOGIN_SERVICE_URL === undefined) {
    return 'https://login.aligntech.co'
  }
  return process.env.VUE_APP_LOGIN_SERVICE_URL
}

export function trackAnalyticEvents (moduleName, eventName) {
  try {
    window.analytics.track(moduleName, {
      eventName: eventName,
      orgId: localStorage.getItem('OrgId'),
      userName: this.$store.getters.fullName,
      userId: localStorage.getItem('UserId')
    })
  } catch (error) {

  }
}

export async function allowBarcodeBlank () {
  const param = JSON.stringify({
    numberType: JSON.stringify(1)
  })
  try {
    const { data } = await axios.post('/api/core/NextNumberService/GetNextNumber', param)
    return !!data?.Body?.AllowBlank
  } catch (error) {
    if (error.response) {
      errorHandler(toast, error.response.statusText)
    }
  }
}

export function getSearchFilter (page) {
  const allSearch = JSON.parse(localStorage.getItem('search-filter')) == null
    ? {} : JSON.parse(localStorage.getItem('search-filter'))
  return allSearch[page]
}

export async function setSearchFilter (page, searchFilter) {
  const allSearch = JSON.parse(localStorage.getItem('search-filter')) == null
    ? {} : JSON.parse(localStorage.getItem('search-filter'))
  allSearch[page] = searchFilter
  localStorage.setItem('search-filter', JSON.stringify(allSearch))
}

export function getColumnFilter (page) {
  const allSearch = JSON.parse(localStorage.getItem('column-filter')) == null
    ? {} : JSON.parse(localStorage.getItem('column-filter'))
  return allSearch[page]
}

export async function setColumnFilter (page, searchFilter) {
  const allSearch = JSON.parse(localStorage.getItem('column-filter')) == null
    ? {} : JSON.parse(localStorage.getItem('column-filter'))
  allSearch[page] = searchFilter
  localStorage.setItem('column-filter', JSON.stringify(allSearch))
}
