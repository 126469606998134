const requisitionHome = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'ModelId',
        type: 'check'
      },
      {
        label: 'Imagen',
        key: 'ImageUrl',
        type: 'Image'
      },
      {
        label: 'Tipo',
        type: String,
        key: 'ItemType',
        sortable: true
      },
      {
        label: 'Modelo',
        type: String,
        key: 'Model',
        sortable: true
      },
      {
        label: 'Fabricante',
        type: String,
        key: 'Manufacturer',
        sortable: true
      },
      {
        label: 'Descripción',
        type: String,
        key: 'Description',
        sortable: true
      },
      {
        label: 'Tarifa mensual',
        type: String,
        key: 'RateSourceValue',
        sortable: true
      },
      {
        label: 'Stock',
        type: Number,
        key: 'Stock',
        sortable: true
      },
      {
        label: 'Cantidad',
        type: Number,
        key: 'Quantity',
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'ModelId',
        type: 'check'
      },
      {
        label: 'Image',
        key: 'ImageUrl',
        type: 'Image'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Tipo',
            type: String,
            key: 'ItemType'
          },
          {
            label: 'Modelo',
            type: String,
            key: 'Model'
          },
          {
            label: 'Fabricante',
            type: String,
            key: 'Manufacturer'
          },
          {
            label: 'Descripción',
            type: String,
            key: 'Description'
          },
          {
            label: 'Tarifa mensual',
            type: String,
            key: 'RateSourceValue'
          },
          {
            label: 'Stock',
            type: String,
            key: 'Stock'
          },
          {
            label: 'Cantidad',
            type: Number,
            key: 'Quantity'
          }
        ]
      }
    ]
  }
}

export default requisitionHome
