const listSearchBrowser = {
  typeDescriptions: {
    1: 'Certificaciones',
    2: 'Clases de artículos',
    3: 'Centros de costos',
    4: 'Códigos de costos',
    5: 'Descripciones de herramientas',
    6: 'Descripcion de materiales',
    7: 'Empleados',
    8: 'Inicio de sesión de empleados',
    9: 'Ubicaciones',
    10: 'Empleados y Ubicaciones',
    11: 'Ubicaciones de abastecimientos',
    12: 'Categorías de herramientas',
    13: 'Categorías de materiales',
    14: 'Fabricantes',
    15: 'Códigos de estados',
    16: 'Unidades de medidas',
    17: 'Vendedores',
    18: 'Empleados transferibles y ubicaciones',
    19: 'Ubicaciones de existencias permitidas',
    20: 'Descripción de Plantillas',
    21: 'Descripción de las Trabajos de calibración',
    22: 'Descripciones de Trabajos de inspección',
    23: 'Descripciones de Trabajos de servicio',
    24: 'Descripciones de solicitudes de servicio',
    25: 'Descripciones de cargos',
    26: 'Plantillas de categorias',
    27: 'Contratistas',
    28: 'Clientes',
    29: 'Departamentos',
    30: 'Conteos de ciclo',
    31: 'Tipos de empleados',
    32: 'Métodos de envio',
    33: 'Clases de servicio',
    34: 'Empleados y ubicaciones',
    35: 'Centros de costos',
    36: 'Códigos de costos',
    37: 'Empleados',
    38: 'Ubicaciones',
    39: 'Ubicaciones de existencias',
    40: 'Tipos de jubilación',
    41: 'Trabajos',
    42: 'Todas las categorias',
    55: 'Categorías de tareas',
    56: 'Prioridades',
    57: 'Estados',
    58: 'Empleados',
    59: 'Ubicaciones de trabajo',
    60: 'Desencadenantes',
    61: 'Ubicaciones'
  },
  labels: {
    all: 'Todo',
    employees: 'Empleados',
    locations: 'Ubicaciones',
    stockLocations: 'Ubicaciones de existencias',
    global: 'Global',
    specific: 'Centros de costos específicos',
    nonStockLocations: 'Ubicaciones sin stock',
    refreshToolTip: 'Refrescar'
  }
}

export default listSearchBrowser
