import axios from 'axios'

export default {
  state: () => ({
    employeesLocationList: [],
    employeesList: [],
    locationList: [],
    stockLocationList: [],
    costCodeList: []
  }),
  mutations: {
    setEmployeesLocationList (state, data) {
      state.employeesLocationList = [...data]
    },
    setEmployeesList (state, data) {
      state.employeesList = [...data]
    },
    setLocationList (state, data) {
      state.locationList = [...data]
    },
    setStockLocationList (state, data) {
      state.stockLocationList = [...data]
    },
    setCostCodeList (state, data) {
      state.costCodeList = [...data]
    },
    resetEmployeesList (state) {
      state.employeesList = []
    },
    resetLocationList (state) {
      state.locationList = []
    },
    resetStockLocationList (state) {
      state.stockLocationList = []
    }
  },
  actions: {
    async locationListByListType (context, { query, listTypeId, stockpointId }) {
      try {
        const listType = await context.dispatch('loadDataByListType', {
          query: query,
          listTypeId: listTypeId,
          stockpointId: stockpointId
        })
        if (listTypeId == 7) {
          context.commit('setEmployeesList', listType)
        } else if (listTypeId == 9) {
          context.commit('setLocationList', listType)
        } else if (listTypeId == 11) {
          context.commit('setStockLocationList', listType)
        } else {
          context.commit('setEmployeesLocationList', listType)
        }
        return listType
      } catch (error) {
        return []
      }
    },
    async searchCostCodes (query, stockpointId, costCenterId) {
      query = ''
      const costCodeList = await this.$store.commit('setShipLoading', query, stockpointId, costCenterId)
      context.commit('setCostCodeList', costCodeList)
    }
  },
  getters: {
    getEmployeesList (state) {
      return state.employeesList
    },
    getLocationList (state) {
      return state.locationList
    },
    getStockLocationList (state) {
      return state.stockLocationList
    },
    getEmployeesLocationList (state) {
      return state.employeesLocationList
    },
    getCostCodeList (state) {
      return state.costCodeList
    }
  }
}
