export default {
  state: () => ({
    isRequisitionGrid: false
  }),
  mutations: {
    setRequistionGrid (state, flag) {
      state.isRequisitionGrid = flag
    }
  },
  actions: {

  },
  getters: {
    isRequisitionGrid (state) {
      return state.isRequisitionGrid
    }
  }
}
