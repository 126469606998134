const listSearchBrowser = {
  typeDescriptions: {
    1: 'Certifications',
    2: 'Item Classes',
    3: 'Cost Centers',
    4: 'Cost Codes',
    5: 'Tool Descriptions',
    6: 'Material Descriptions',
    7: 'Employees',
    8: 'Employee Logins',
    9: 'Locations',
    10: 'Employees and Locations',
    11: 'Stock Locations',
    12: 'Tool Categories',
    13: 'Material Categories',
    14: 'Manufacturers',
    15: 'Status Codes',
    16: 'Units of Measure',
    17: 'Vendors',
    18: 'Transferrable Employees and Locations',
    19: 'Permitted Stock Locations',
    20: 'Template Descriptions',
    21: 'Calibration Task Descriptions',
    22: 'Inspection Task Descriptions',
    23: 'Service Task Descriptions',
    24: 'Service Request Descriptions',
    25: 'Charge Descriptions',
    26: 'Template Categories',
    27: 'Contractors',
    28: 'Customers',
    29: 'Departments',
    30: 'Cycle Counts',
    31: 'Employee Types',
    32: 'Shipping Methods',
    33: 'Service Classes',
    34: 'Employees and Locations',
    35: 'Cost Centers',
    36: 'Cost Codes',
    37: 'Employees',
    38: 'Locations',
    39: 'Stock Locations',
    40: 'Retirement Types',
    41: 'Jobs',
    42: 'All Categories',
    55: 'Task Categories',
    56: 'Priorities',
    57: 'Statuses',
    58: 'Employees',
    59: 'Job Locations',
    60: 'Triggers',
    61: 'Locations'
  },
  labels: {
    all: 'All',
    employees: 'Employees',
    locations: 'Locations',
    stockLocations: 'Stock Locations',
    global: 'Global',
    specific: 'Cost Center Specific',
    nonStockLocations: 'Non Stock Locations',
    refreshToolTip: 'Refresh'
  }
}

export default listSearchBrowser
