const bleTable = {
  tableHeader: {
    sensors: {
      desktop: [
        {
          label: '',
          key: 'BleGatewayId',
          type: 'check'
        },
        {
          label: 'Asignación de puerto',
          key: 'LocationDescription',
          sortable: true
        },
        {
          label: 'Área',
          key: 'Area',
          sortable: true
        },
        {
          label: 'Tipo',
          key: 'GatewayType',
          sortable: true
        },
        {
          label: 'Fabricante',
          key: 'Manufacturer',
          sortable: true
        },
        {
          label: 'Modelo',
          key: 'Model',
          sortable: true
        },
        {
          label: 'Nivel de bateria',
          key: 'BatteryLevel',
          sortable: true
        },
        {
          label: 'Dirección MAC',
          key: 'MacAddress',
          sortable: true
        },
        {
          label: 'Creado',
          key: 'CreatedOn',
          sortable: true
        },
        {
          label: 'Acción',
          key: ''
        }
      ],
      mobile:
      [
        {
          label: '',
          key: 'BleGatewayId',
          type: 'check'
        },
        {
          label: 'Acción',
          key: ''
        },
        {
          label: 'Info',
          data: [
            {
              label: 'Asignación de puerto',
              key: 'LocationDescription',
              sortable: true
            },
            {
              label: 'Área',
              key: 'Area',
              sortable: true
            },
            {
              label: 'Tipo',
              key: 'GatewayType',
              sortable: true
            },
            {
              label: 'Fabricante',
              key: 'Manufacturer',
              sortable: true
            },
            {
              label: 'Modelo',
              key: 'Model',
              sortable: true
            },
            {
              label: 'Nivel de bateria',
              key: 'BatteryLevel',
              sortable: true
            },
            {
              label: 'Dirección MAC',
              key: 'MacAddress',
              sortable: true
            },
            {
              label: 'Creado',
              key: 'CreatedOn',
              sortable: true
            }
          ]
        }
      ]
    },
    tags: {
      desktop: [
        {
          label: '',
          key: 'BleTagId',
          type: 'check'
        },
        {
          label: 'Jubilada',
          key: 'Deleted',
          type: Boolean,
          sortable: true
        },
        {
          label: 'Dirección MAC',
          key: 'MacAddress'
        },
        {
          label: 'Modelo de etiqueta',
          key: 'TagModel',
          sortable: true
        },
        {
          label: 'Número de serie',
          key: 'SerialNumber',
          sortable: true
        },
        {
          label: 'Nivel de bateria',
          key: 'BatteryLevel',
          sortable: true
        },
        {
          label: 'Último escaneo',
          key: 'LastScan',
          type: Date,
          sortable: true
        },
        {
          label: 'Id del artículo',
          key: 'ItemNumber',
          sortable: true
        },
        {
          label: 'Marca del artículo',
          key: 'ItemManufacturer',
          sortable: true
        },
        {
          label: 'Modelo de artículo',
          key: 'ItemModel',
          sortable: true
        },
        {
          label: 'Descripción del Artículo',
          key: 'ItemDescription',
          sortable: true
        }
      ],
      mobile: [
        {
          label: '',
          key: 'BleTagId',
          type: 'check'
        },
        {
          label: 'Info',
          data: [
            {
              label: 'Jubilada',
              key: 'Deleted',
              type: Boolean,
              sortable: true
            },
            {
              label: 'Dirección MAC',
              key: 'MacAddress'
            },
            {
              label: 'Modelo de etiqueta',
              key: 'TagModel',
              sortable: true
            },
            {
              label: 'Número de serie',
              key: 'SerialNumber',
              sortable: true
            },
            {
              label: 'Nivel de bateria',
              key: 'BatteryLevel',
              sortable: true
            },
            {
              label: 'Último escaneo',
              key: 'LastScan',
              type: Date,
              sortable: true
            },
            {
              label: 'Id del artículo',
              key: 'ItemNumber',
              sortable: true
            },
            {
              label: 'Marca del artículo',
              key: 'ItemManufacturer',
              sortable: true
            },
            {
              label: 'Modelo de artículo',
              key: 'ItemModel',
              sortable: true
            },
            {
              label: 'Descripción del Artículo',
              key: 'ItemDescription',
              sortable: true
            }
          ]
        }
      ]
    },
    tagsGPS: {
      desktop: [
        {
          label: '',
          key: 'BleTagId',
          type: 'check'
        },
        {
          label: 'Jubilado',
          key: 'Deleted',
          type: Boolean,
          sortable: true
        },
        {
          label: 'Identificación única',
          key: 'UniqueId',
          sortable: true
        },
        {
          label: 'Dirección MAC',
          key: 'MacAddress',
          sortable: true
        },
        {
          label: 'Modelo De Etiqueta',
          key: 'MakeModel',
          sortable: true
        },
        {
          label: 'Número de serie',
          key: 'SerialNumber',
          sortable: true
        },
        {
          label: 'GPS',
          key: 'IsGPS',
          sortable: true
        },
        {
          label: 'Nivel de bateria',
          key: 'BatteryLevel',
          sortable: true
        },
        {
          label: 'Último escaneo',
          key: 'LastScan',
          type: Date,
          sortable: true
        },
        {
          label: 'Id del artículo',
          key: 'ItemNumber',
          sortable: true
        },
        {
          label: 'Marca del artículo',
          key: 'ItemManufacturer',
          sortable: true
        },
        {
          label: 'Modelo de artículo',
          key: 'ItemModel',
          sortable: true
        },
        {
          label: 'Descripción del Artículo',
          key: 'ItemDescription',
          sortable: true
        }
      ],
      mobile:
      [
        {
          label: '',
          key: 'BleTagId',
          type: 'check'
        },
        {
          label: 'Info',
          data: [
            {
              label: 'Jubilado',
              key: 'Deleted',
              type: Boolean,
              sortable: true
            },
            {
              label: 'Identificación única',
              key: 'UniqueId',
              sortable: true
            },
            {
              label: 'Dirección MAC',
              key: 'MacAddress',
              sortable: true
            },
            {
              label: 'Modelo De Etiqueta',
              key: 'MakeModel',
              sortable: true
            },
            {
              label: 'Número de serie',
              key: 'SerialNumber',
              sortable: true
            },
            {
              label: 'GPS',
              key: 'IsGPS',
              sortable: true
            },
            {
              label: 'Último escaneo',
              key: 'LastScan',
              type: Date,
              sortable: true
            },
            {
              label: 'Eliminado',
              key: 'Deleted',
              type: Boolean,
              sortable: true
            },
            {
              label: 'Id del artículo',
              key: 'ItemNumber',
              sortable: true
            },
            {
              label: 'Marca del artículo',
              key: 'ItemManufacturer',
              sortable: true
            },
            {
              label: 'Modelo de artículo',
              key: 'ItemModel',
              sortable: true
            },
            {
              label: 'Descripción del Artículo',
              key: 'ItemDescription',
              sortable: true
            }
          ]
        }
      ]
    },
    dropdownActions: [
      {
        title: 'Editar',
        actionName: 'editItem',
        permissions: []
      },
      {
        title: 'Borrar',
        actionName: 'deleteItem',
        permissions: []
      }
    ],
    actionButton: 'Agregar puerta',
    editOptionText: 'Editar puerta de enlace',
    buttonAddTag: 'Agregar Etiqueta',
    deleteOptionText: 'Eliminar puerta de enlace',
    viewOptionText: 'Ver puerta de enlace'
  },
  warnings: {
    0: 'Sólo se puede editar una puerta a la vez'
  }
}

export default bleTable
