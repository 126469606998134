const materialCategoryItems = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'assetId',
        type: 'check'
      },
      {
        label: 'Categoría',
        key: 'category',
        sortable: true,
        type: String
      },
      {
        label: 'Número',
        key: 'itemNumber',
        sortable: true,
        type: String
      },
      {
        label: 'Descripción',
        key: 'description',
        sortable: true,
        type: String
      },
      {
        label: 'Modelo',
        key: 'model',
        sortable: true,
        type: String
      },
      {
        label: 'Cant.',
        key: 'quantity',
        sortable: true,
        type: Number
      },
      {
        label: 'Asignado',
        key: 'pickedQuantity',
        sortable: true,
        type: Number,
        flag: 'isShowPickedItemState'
      },
      {
        label: 'Disponible',
        key: 'available',
        sortable: true,
        type: Number,
        flag: 'isShowPickedItemState'
      },
      {
        label: 'Uom',
        key: 'uom',
        sortable: true,
        type: String
      },
      {
        label: 'Ubicación de almacen',
        key: 'assignedTo',
        sortable: true,
        type: String
      },
      {
        label: 'contenedor',
        key: 'binId',
        sortable: true,
        type: String
      },
      {
        label: 'Código de barras',
        key: 'barcode',
        sortable: true,
        type: String
      },
      {
        label: 'Notas',
        key: 'notes',
        sortable: true,
        type: String
      },
      {
        label: 'Costo de la última compra',
        key: 'lastPurchaseCost',
        sortable: true,
        type: Number
      }
    ],
    mobile: [
      {
        label: '',
        key: 'assetId',
        type: 'check'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Categoría',
            key: 'category',
            sortable: true,
            type: String
          },
          {
            label: 'Número',
            key: 'itemNumber',
            sortable: true,
            type: String
          },
          {
            label: 'Descripción',
            key: 'description',
            sortable: true,
            type: String
          },
          {
            label: 'Modelo',
            key: 'model',
            sortable: true,
            type: String
          },
          {
            label: 'Cant.',
            key: 'quantity',
            sortable: true,
            type: Number
          },
          {
            label: 'Asignado',
            key: 'pickedQuantity',
            sortable: true,
            type: Number,
            flag: 'isShowPickedItemState'
          },
          {
            label: 'Disponible',
            key: 'available',
            sortable: true,
            type: Number,
            flag: 'isShowPickedItemState'
          },
          {
            label: 'Uom',
            key: 'uom',
            sortable: true,
            type: String
          },
          {
            label: 'Ubicación de almacen',
            key: 'assignedTo',
            sortable: true,
            type: String
          },
          {
            label: 'contenedor',
            key: 'binId',
            sortable: true,
            type: String
          },
          {
            label: 'Código de barras',
            key: 'barcode',
            sortable: true,
            type: String
          },
          {
            label: 'Notas',
            key: 'notes',
            sortable: true,
            type: String
          },
          {
            label: 'Costo de la última compra',
            key: 'lastPurchaseCost',
            sortable: true,
            type: Number
          }
        ]
      }
    ]
  }
}

export default materialCategoryItems
