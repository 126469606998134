const countTicketTabCountSummaryTable = {
  tableHeader: {
    desktop: [
      {
        label: 'Categoría',
        key: 'Category',
        sortable: true
      },
      {
        label: 'Artículo',
        key: 'ItemNumber',
        sortable: true
      },
      {
        label: 'T',
        key: 'Type',
        sortable: true
      },
      {
        label: 'Descripción del Artículo',
        key: 'ItemDescription',
        sortable: true
      },
      {
        label: 'Fabricante',
        key: 'Manufacturer',
        sortable: true
      },
      {
        label: 'Modelo',
        key: 'Model',
        sortable: true
      },
      {
        label: 'Esperado',
        key: 'InStock',
        sortable: true
      },
      {
        label: 'Cuenta total',
        key: 'Total',
        sortable: true
      },
      {
        label: '+/-',
        key: 'Variance',
        sortable: true
      },
      {
        label: 'Propietario',
        key: 'Owner',
        sortable: true
      },
      {
        label: 'Equipo',
        key: 'KitNumber',
        sortable: true
      },
      {
        label: 'Estado',
        key: 'ItemStatus',
        sortable: true
      },
      {
        label: 'UP',
        nested: true,
        nestedKey: 'Key',
        key: 'PersonalIssue',
        sortable: true
      },
      {
        label: 'contenedor',
        key: 'Bin',
        sortable: true
      },
      {
        label: '',
        key: 'actionField',
        sortable: false
      }
    ],
    mobile: [
      {
        label: 'Info',
        data: [
          {
            label: 'Categoría',
            key: 'Category',
            sortable: true
          },
          {
            label: 'Artículo',
            key: 'ItemNumber',
            sortable: true
          },
          {
            label: 'T',
            key: 'Type',
            sortable: true
          },
          {
            label: 'Descripción del Artículo',
            key: 'ItemDescription',
            sortable: true
          },
          {
            label: 'Fabricante',
            key: 'Manufacturer',
            sortable: true
          },
          {
            label: 'Modelo',
            key: 'Model',
            sortable: true
          },
          {
            label: 'Esperado',
            key: 'InStock',
            sortable: true
          },
          {
            label: 'Cuenta total',
            key: 'Total',
            sortable: true
          },
          {
            label: '+/-',
            key: 'Variance',
            sortable: true
          },
          {
            label: 'Propietario',
            key: 'Owner',
            sortable: true
          },
          {
            label: 'Equipo',
            key: 'KitNumber',
            sortable: true
          },
          {
            label: 'Estado',
            key: 'ItemStatus',
            sortable: 'true'
          },
          {
            label: 'UP',
            key: 'ModelId',
            sortable: true
          },
          {
            label: 'UP',
            nested: true,
            nestedKey: 'Key',
            key: 'PersonalIssue',
            sortable: true
          },
          {
            label: 'contenedor',
            key: 'Bin',
            sortable: true
          }
        ]
      },
      {
        label: '',
        key: 'actionField',
        sortable: false
      }
    ]
  }
}

export default countTicketTabCountSummaryTable
