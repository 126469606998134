const requisitionHome = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'ModelId',
        type: 'check'
      },
      {
        label: 'Image',
        key: 'ImageUrl',
        type: 'Image'
      },
      {
        label: 'Type',
        type: String,
        key: 'ItemType',
        sortable: true
      },
      {
        label: 'Model',
        type: String,
        key: 'Model',
        sortable: true
      },
      {
        label: 'Manufacturer',
        type: String,
        key: 'Manufacturer',
        sortable: true
      },
      {
        label: 'Description',
        type: String,
        key: 'Description',
        sortable: true
      },
      {
        label: 'Monthly rate',
        type: String,
        key: 'RateSourceValue',
        sortable: true
      },
      {
        label: 'Stock',
        type: String,
        key: 'Stock',
        sortable: true
      },
      {
        label: 'Quantity',
        type: Number,
        key: 'Quantity',
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'ModelId',
        type: 'check'
      },
      {
        label: 'Image',
        key: 'ImageUrl',
        type: 'Image'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Type',
            type: String,
            key: 'ItemType'
          },
          {
            label: 'Model',
            type: String,
            key: 'Model'
          },
          {
            label: 'Manufacturer',
            type: String,
            key: 'Manufacturer'
          },
          {
            label: 'Description',
            type: String,
            key: 'Description'
          },
          {
            label: 'Monthly rate',
            type: String,
            key: 'RateSourceValue'
          },
          {
            label: 'Stock',
            type: String,
            key: 'Stock'
          },
          {
            label: 'Quantity',
            type: Number,
            key: 'Quantity'
          }
        ]
      }
    ]
  }
}

export default requisitionHome
